
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/AccCat";


export function getAccCatTreeSearch(search) {
 
  return http.post(apiEndpoint + `/GetTreeSearch/?search=${search}`);
}


