import { message } from "antd";
import _t from "../../languages/translate";
import { doMigrate } from "../../services/Authorization/dbService";
import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;
export function handleEx(exp, obj = null) {
  debugger
  const showPromiseConfirm = () => {
    confirm({
      title: _t("strThisVersionHasExpired"),
      icon: <ExclamationCircleFilled />,
      content: _t("msgVersionWillBeUpdated"),
      direction: _t("langDiriction"),
      okText: _t("strOK"),
      cancelText: _t("strCancel"),
      onOk() {
        setTimeout(async () => {
          await doMigrate();
        }, 1000);
      },
      onCancel() {},
    });
  };

  if (exp?.response) {
    if (exp?.response?.data?.message === "msgClosedPeriod") {
      message.error(_t("msgCannotMakeActionTimeClosed"));
      return;
    }
    if (exp?.response?.data?.message === "ex:DataBaseNotUpdatedToLastVersion") {
      showPromiseConfirm();
      return;
    } else if (exp?.response?.data?.status === 400) {
      message.error(
        _t("badReqError400") + exp?.response?.data?.errors?.id?.join(" _ "),
        10
      );
    } else if (exp?.response?.data?.errors?.ConfirmPassword) {
      message.error(_t("msgPassNotMatch"), 3);
      obj?.IX_Password?.current?.focus();
      obj?.IX_Password?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("UserName")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_UserName?.current?.focus();
      obj?.IX_UserName?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_Code")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_Code?.current?.focus();
      obj?.IX_Code?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_NameAr")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_NameAr?.current?.focus();
      obj?.IX_NameAr?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_NameEn")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_NameEn?.current?.focus();
      obj?.IX_NameEn?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_Name")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_Name?.current?.focus();
      obj?.IX_Name?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_StartCode")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_StartCode?.current?.focus();
      obj?.IX_StartCode?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_Date")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_Date?.current?.focus();
      obj?.IX_Date?.current?.select();
    } else if (exp?.response?.data?.ex?.includes("IX_Currency")) {
      message.error(exp?.response?.data?.message || _t("msgUnknownError"), 3);
      obj?.IX_Currency?.current?.focus();
      obj?.IX_Currency?.current?.select();
    } else {
      message.error(exp?.response?.data?.message,3);
    }
  } else if (exp?.request) {
    message.error( _t("msgServerDisconnected") + " || " + exp?.response?.data?.ex, 3);
  } else if (
    exp.message === "Cannot read properties of undefined (reading 'data')"
  ) {
  } else {
    message.error(_t("msgUnknownError") +" || " + exp?.response?.data?.ex,3);
  }

}
