import React, { useState, useRef } from "react";
import { Form, Modal, Divider, Spin, Card,Button} from "antd";
import _t from "../../../languages/translate";
import { _titleByType, _options, _showByType } from "./healper";
import { _content, _tag, _title, _titleTag } from "./empContent";
import { _contentHR, _tagHR, _titleHR } from "./hrContent";
import { _contentMgr, _tagMgr, _titleMgr } from "./mgrContent";
import Media from "react-media";
import Draggable from "react-draggable";

const PrivOrder = ({ showModal, setShowModal, privewObj }) => {
  const [loadData, setLoadData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [form] = Form.useForm();

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      form.resetFields();
      setLoadData(false);
    }, 50);
  };

  return (
    <Modal
      centered
      width={800}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              <Media query="(max-width: 1024px)">{setDisabled(false)}</Media>
  
            }
          }}
          onMouseOut={() => {
            <Media query="(max-width: 1024px)">{setDisabled(true)}</Media>
    
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {privewObj?.reqApp?.reqAppTypeName} : {_titleTag(privewObj?.reqApp)}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={[]}
    >
      <Divider />
      <Spin spinning={loadData} tip={_t("strLoaging")}>
        <Card title={_title(privewObj?.reqApp)}
          headStyle={{padding:"0 12px"}}>
          {_content(privewObj?.reqApp)}
        </Card>

        {privewObj?.resMgr && (
          <>
            <br />
            <Card title={_titleMgr(privewObj?.resMgr)}
           headStyle={{padding:"0 12px"}}>
              {_contentMgr(privewObj?.resMgr)}
            </Card>
          </>
        )}

        {privewObj?.resHr && (
          <>
            <br />
            <Card title={_titleHR(privewObj?.resHr)}
            headStyle={{padding:"0 12px"}}>
              {_contentHR(privewObj?.resHr)}
            </Card>
          </>
        )}
      </Spin>
      <Divider/>
      <Button
              onClick={() => setShowModal(false)}
              size="large"
              disabled={loadData}
            >
              {_t("strClose")}
            </Button>
    </Modal>
  );
};

export default PrivOrder;
