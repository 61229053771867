import { Space, Tag, Divider, Typography, Row, Col } from "antd";
import { emptString } from "../../helper/helperMethods";
import Map from "./Map";
import _t from "../../../languages/translate";
const { Text } = Typography;

export const _tag = (e) => (
  <Tag
    color={
      e?.status === 2
        ? "green"
        : e?.status === 1
        ? "red"
        : e?.status === 3
        ? "purple"
        : "blue"
    }
  >
    {e?.status === 2
      ? `${_t("strAccepted")}`
      : e?.status === 1
      ? `${_t("strRejected")}`
      : e?.status === 3
      ? `${_t("strApplied")}`
      : _t("strWaiting")}
  </Tag>
);

export const _title = (e) => (
  <Space wrap size="small" className="spaceRes">
    <Text className="title" strong>
      {e?.empName}
    </Text>
    {_tag(e)}
  </Space>
);

export const _extra = (e) => (
  <Text className="extra" strong>
    {e?.reqDate}
  </Text>
);

export const _content = (e) => (
  <>
    <Space direction="vertical" style={{ marginTop: 5 }}>
      <Space size="small" split={<Divider type="vertical" />}>
        {emptString(e?.typeName, false) && (
          <Text type="secondary">
            {_t("strType")} :{" "}
            <Text mark strong>
              {e?.typeName}
            </Text>
          </Text>
        )}
        {emptString(e?.date, false) && (
          <Text type="secondary">
            {_t("strDate")} : <Text strong>{e?.date}</Text>
          </Text>
        )}
        {emptString(e?.time, false) && (
          <Text type="secondary">
            {_t("strTime")} : <Text strong>{e?.time}</Text>
          </Text>
        )}
      </Space>
    </Space>
    {emptString(e?.latitude, false) ? (
      <>
        <Divider style={{ margin: "5px 0px" }} />
        <iframe
          style={{ border: 0 }}
          height="200"
          width="100%"
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_Google_Key}
    &q=${e?.latitude},${e?.longitude}`}
        ></iframe>
        {/* <Map
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAxsB9tA9cry7c640uKFN1F1zvGU1TBPO8"
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "15vh", width: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          center={{ lat: e?.latitude, lng: e?.longitude }}
          zoom={18}
          places={[{ lat: e?.latitude, lng: e?.longitude }]}
        /> */}
      </>
    ) : (
      <>
        <br />
        <br />
        <Text type="secondary">
          {_t("strMap")} :{" "}
          <Text strong underline type="warning">
            {_t("strNonLocationEmp")}
          </Text>
        </Text>
      </>
    )}
  </>
);

export const _pageSizeKey = "pageSize";
