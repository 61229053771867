export const authKeys = {
  repLedger: "Rep_Accs_Ledger",
  repJournalMove: "Rep_Accs_JournalMove",
  repTrialBalance: "Rep_Accs_TrialBalance",
  repItemMove: "Rep_Items_ItemMove",
  repItemInStock: "Rep_Items_ItemInStock",
  repInvMove: "Rep_Items_InvMove",
  repMunfMove: "Rep_Items_MunfMove",
  ents: "Opr_Ents",
  entsBrowse: "Opr_Ents_Browse",
  invs: "Opr_Invs",
  munfs: "Opr_Munfs",
  munfModels: "Def_Munf_MunfModels",
  acc: "Def_Accs_Acc",
  client: "Def_Accs_Client",
  finalAcc: "Def_Accs_FinalAcc",
  itemFinalAcc:"Def_Items_ItemFinalAcc",
  journal: "Def_Accs_Journal",
  payMethod: "Def_Accs_PayMethod",
  item: "Def_Items_Item",
  unit: "Def_Items_Unit",
  priceMethod: "Def_Items_PriceMethod",
  priceList: "Def_Items_PriceList",
  curr: "Def_Currs_Curr",
  currRate: "Def_Currs_CurrRate",
  store: "Def_Basic_Store",
  branch: "Def_Basic_Branch",
  company: "Def_Basic_Company",
  user: "Sett_Security_User",
  role: "Sett_Security_Role",
  chPass: "Sett_Security_ChPass",
  impFromExcel: "Sett_Imports_ImpFromExcel",
  costCenter:"Def_Accs_CostCenter",
};

