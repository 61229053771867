import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/Emp";

export function getCurrUser() {
  return http.post(`${apiEndpoint}/GetCurrent`);
}

export function getAllEmp() {
  return http.post(`${apiEndpoint}/GetActiveSearchIgnoreCurrent`);
}
