import { LanguageContext } from "./Language";
import React, { useContext, useEffect } from "react";
import { setLanguage } from "../services/Authorization/usersService";
import { refreshToken } from "../services/Authorization/authService";
import { Button,Modal } from "antd";
import _t from "./translate";
import ImageAr from "../images/svg/flagIcons/Ar.svg";
import ImageEn from "../images/svg/flagIcons/En.svg";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function LanguageSelector({ onHandelLang }) {
  const { userLanguage, userLanguageChange } = useContext(
    LanguageContext
  );
  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: _t("strMsgChangeLang"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strMsgChangeLangDis"),
      direction:_t("langDiriction"),
      onOk(){
        langChange(userLanguage === 'en' ? 'ar' : 'en')     
      }   

    });
  };



  const langChange = async (value) => {
    try {
      await setLanguage(value); // save lang
      await refreshToken(); // Refresh Token
      userLanguageChange(value);

      window.location.reload();
    } catch (error) {

    }
  };

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem("lang");
    onHandelLang(defaultLanguage);
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange, onHandelLang]);

  return (
    <Button size="small" onClick={showConfirm} type="link">
      {userLanguage !== "en" ? <img src={ImageEn}  alt="en-flag" style={{ width: "24px" }} /> :  <img src={ImageAr} alt="ar-flag" style={{ width: "24px" }} />}
    </Button>
  );
}
