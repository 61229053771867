import React from "react";
import { ResetPassword } from "../../../services/Authorization/ResetPasswordService";
import { Form, Modal, message, Input } from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";

const ChangePass = ({ idVal, visible, setVisible }) => {
    const [form] = Form.useForm();
   

    const handleOk = async (values) => {
        try {
            values.id = idVal;
            delete values.confirmNewPassword;
            const res = await ResetPassword(values);       
            form.resetFields();
            message.success(res.data?.message);
            setVisible(false);
        } catch (error) {
            handleEx(error);
        }
    };

    return (
        <Modal
            title={_t("strResetPassword")}
            visible={visible}
            okText={_t("strSubmit")}
            cancelText={_t("strCancel")}
            onOk={() => {
                form.validateFields().then((values) => { handleOk(values) })
            }}
            onCancel={() => setVisible(false)}
        >
            <Form
                layout="vertical"
                form={form}
                name="nest-messages"
                onFinish={handleOk}
            >      
                <Form.Item
                    name="newPassword"
                    label={_t("strPassword")}
                    rules={[{ required: true, message: _t("msgInputYourPass") }]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmNewPassword"
                    label={_t("strConfirmPassword")}
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        { required: true, message: _t("msgInputYourConPass") },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(_t("msgPassNotMatch"));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    )

}
export default ChangePass;








