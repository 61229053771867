import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  InputNumber,
  Card,
  Tabs,
  Checkbox,
} from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActiveTreeRepAccount } from "../../../services/AccountService";
import { getAccCatTreeSearch } from "../../../services/AccountCatService";
import { getActiveTreeRepCostCenters } from "../../../services/CostCenterService";
import {
  getCurrActiveSearch,
  getDefaultIdByAccount,
} from "../../../services/Items/currenciesService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import {
  emptArr,
  emptDate,
  emptString,
  emptValue,
  dateRange,
  convertISOString
} from "../../helper/helperMethods";
import Draggable from "react-draggable";

const FilterTrialBalance = ({
  visible,
  setVisible,
  onHandelData,
  onFilterData,
}) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const draggleRef = useRef(null);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [showOptCurr, setShowOptCurr] = useState(true);
  const [accountOpt, setAccountOpt] = useState([]);
  const [accountState, setAccountState] = useState("");
  const [accountTreeId, setAccountTreeId] = useState([]);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [accountTypeState, setAccountTypeState] = useState("");
  const [accountTypeTreeId, setAccountTypeTreeId] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      handleSearchCurrency("");
      onAccountsTypeSearch("");
      onAccountsSearch("");
      onCostCenterSourceSearch("");
      if (onFilterData?.reportFilter !== undefined) {
        
        form.setFieldsValue({
          accCatIds: emptArr(onFilterData?.reportFilter?.accCatIds, undefined),
          accIds: emptArr(onFilterData?.reportFilter?.accIds, undefined),
          CostCentersIds: emptArr(onFilterData?.reportFilter?.costCentersIds, undefined),
          fromDate: emptDate(onFilterData?.reportFilter?.fromDate, ""),
          toDate: emptDate(onFilterData?.reportFilter?.toDate, ""),
          fromPostDate: emptDate(onFilterData?.reportFilter?.fromPostDate, ""),
          toPostDate: emptDate(onFilterData?.reportFilter?.toPostDate, ""),
          accCurrIds: emptArr(onFilterData.reportFilter?.accCurrIds, undefined),
          transCurrIds: emptArr(
            onFilterData.reportFilter?.transCurrIds,
            undefined
          ),
          totalViewTypes: emptArr(
            onFilterData.reportFilter?.totalViewTypes,
            undefined
          ),
          showAccType: emptArr(
            onFilterData.reportFilter?.showAccType,
            undefined
          ),
          entPostTypes: emptArr(
            onFilterData.reportFilter?.entPostTypes,
            undefined
          ),
          currId: emptString(onFilterData?.reportFilter?.currId, undefined),
          treeLevel: emptValue(onFilterData?.reportFilter?.treeLevel,undefined),

          opEntIsPrevBalance: onFilterData.reportFilter?.opEntIsPrevBalance,
          showRepByAccCurr: onFilterData.reportFilter?.showRepByAccCurr,
          showAccCurr: onFilterData.reportFilter?.showAccCurr,
        });
      }
    };
    fetchData();
  }, [onFilterData]);

  const GetDefaultIdByAccount = async (ids) => {
    try {
      if (ids.length === 1) {
        const { data: data } = await getDefaultIdByAccount(ids[0]);
        form.setFieldsValue({
          currId: data?.data,
        });
      } else {
        form.setFieldsValue({
          currId: window.localStorage.getItem("currId"),
        });
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.fromDate = convertISOString(values?.fromDate);
    values.toDate = convertISOString(values?.toDate);
    values.fromPostDate = convertISOString(values?.fromPostDate);
    values.toPostDate = convertISOString(values?.toPostDate);

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };
    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };



  const [costCentersSourceOpt, setCostCentersSourceOpt] = useState([]);
  const [costCentersSourceState, setCostCentersSourceState] = useState("");
  const [costCentersSourceTreeId, setCostCentersSourceTreeId] = useState([""]);

  const onCostCenterSourceSearch = async (_Search) => {
    try {
      setCostCentersSourceState(_Search);
      const { data: result } = await getActiveTreeRepCostCenters({search: _Search});
      setCostCentersSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onCostCentersSourceChange = (value) => {
    setCostCentersSourceTreeId(value);
  };


  const onAccountsSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: accountTreeId,
        clientIds: [],
        accCatIds: accountTypeTreeId,
      };
      setAccountState(searchValue);
      const { data: result } = await getActiveTreeRepAccount(obj);
      setAccountOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onAccountsChange = (value) => {
    setAccountTreeId(value);
  };

  const onAccountsTypeSearch = async (searchValue) => {
    try {
      setAccountTypeState(searchValue);
      const { data: result } = await getAccCatTreeSearch(searchValue, "");
      setAccountTypeOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onAccountsTypeChange = (value) => {
    setAccountTypeTreeId(value);
  };

  useEffect(() => {
    onAccountsSearch("");
  }, [accountTreeId, accountTypeTreeId]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strTrialBalance")}
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          fromDate: dateRange("month"),
          toDate: dateRange(),
          currId: window.localStorage.getItem("currId"),
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item label={_t("strAccount")}>
                    <Input.Group compact>
                      <SelectTreeMulty
                        name="accCatIds"
                        label=""
                        noStyle
                        style={{ width: "30%" }}
                        placeholder={_t("strAccountType")}
                        opt={accountTypeOpt}
                        setOpt={setAccountTypeOpt}
                        state={accountTypeState}
                        setState={setAccountTypeState}
                        treeId={accountTypeTreeId}
                        setTreeId={setAccountTypeTreeId}
                        onChange={onAccountsTypeChange}
                      />
                      <SelectTreeMulty
                        name="accIds"
                        label=""
                        noStyle
                        style={{ width: "70%" }}
                        placeholder={_t("strAccount")}
                        opt={accountOpt}
                        state={accountState}
                        setState={setAccountState}
                        treeId={accountTreeId}
                        setTreeId={setAccountTreeId}
                        onChange={(e) => {
                          onAccountsChange(e);
                          GetDefaultIdByAccount(e);
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      lable={_t("strDate")}
                      fromName="fromDate"
                      toName="toDate"
                      defaultRange={4}
                    />
                  </Row>
                </Col>

                <Col span={24}>
                  <Form.Item name="TreeLevel" label={_t("strLevel")}>
                    <InputNumber
                      size="small"
                      placeholder={_t("strLevel")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="المتقدم" key="2" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      lable={_t("strPostingDate")}
                      fromName="fromPostDate"
                      toName="toPostDate"
                    />
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="accCurrIds" label={_t("strAccCurr")}>
                    <SelectMulty
                      placeholder={_t("strNotSelected")}
                      fieldNames={{
                        label: "currName",
                        value: "currId",
                      }}
                      opt={currencyOpt}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="transCurrIds" label={_t("strTranseCurr")}>
                    <SelectMulty
                      placeholder={_t("strNotSelected")}
                      fieldNames={{
                        label: "currName",
                        value: "currId",
                      }}
                      opt={currencyOpt}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <SelectTreeMulty
                    name="costCentersIds"
                    label={_t("strCostCenter")}
                    opt={costCentersSourceOpt}
                    setOpt={setCostCentersSourceOpt}
                    state={costCentersSourceState}
                    setState={setCostCentersSourceState}
                    treeId={costCentersSourceTreeId}
                    setTreeId={setCostCentersSourceTreeId}
                    onChange={onCostCentersSourceChange}
                  />
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="الخيارات" key="3" forceRender>
            <Row gutter={12}>
              <Col span={16}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item name="totalViewTypes" label={_t("strTotal")}>
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strTermBalance"),
                                filter1: _t("strTermBalance"),
                              },
                              {
                                id: 1,
                                name: _t("strPreviousBalance"),
                                filter1: _t("strPreviousBalance"),
                              },
                              {
                                id: 2,
                                name: _t("strFinalBalance"),
                                filter1: _t("strFinalBalance"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="showAccType" label={_t("strShowAcc")}>
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strBlank"),
                                filter1: _t("strBlank"),
                              },
                              {
                                id: 1,
                                name: _t("strBalanced"),
                                filter1: _t("strBalanced"),
                              },
                              {
                                id: 2,
                                name: _t("strNonBalanced"),
                                filter1: _t("strNonBalanced"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="entPostTypes"
                          label={_t("strEntryStatus")}
                        >
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strPosted"),
                                filter1: _t("strPosted"),
                              },
                              {
                                id: 1,
                                name: _t("strUnPosted"),
                                filter1: _t("strUnPosted"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      {showOptCurr && (
                        <Col span={24}>
                          <Form.Item name="currId" label={_t("strCurrency")}>
                            <SelectSingle
                            allowClear={false}
                              placeholder={_t("strNotSelected")}
                              fieldNames={{
                                label: "currName",
                                value: "currId",
                              }}
                              opt={currencyOpt}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{
                    width: "100%",
                    height: "95%",
                    margin: "5px 0px 5px 0px",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Form.Item
                    name="opEntIsPrevBalance"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>
                      {_t("strConvertOpeningEntryToPrevBalance")}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="showRepByAccCurr"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox
                      onChange={(e) => {
                        setShowOptCurr(!e.target.checked);
                        form.setFieldsValue({
                          currId: null,
                          optCurrRate: null,
                        });
                      }}
                    >
                      {_t("strShowRepByAccCurr")}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="showAccCurr"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>{_t("strShowAccCurr")}</Checkbox>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FilterTrialBalance;
