import React, { useEffect, useMemo, useState } from "react";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { Button, Row, Col, message, Space, Card, Spin } from "antd";
import { dateTimeNow } from "../../helper/helperMethods";
import { saveAttLog } from "../../../services/AttLogService";
import { handleEx } from "../../helper/handleException";
import { useHistory } from "react-router-dom";
import map from "../../../images/gif/map400.gif";
import no from "../../../images/gif/No400.gif";
import yes from "../../../images/gif/done400.gif";

import ValidateLocation from "./ValidateLocation";
const PageRegistration = () => {
  const [loadIN, setLoadIN] = useState(false);
  const [loadOut, setLoadOut] = useState(false);
  const [isValidLocation, setIsValidLocation] = useState(
    localStorage.getItem("attLogType") === "2" ? false : true
  );

  const [regState, setRegState] = useState("");
  const [objLocation, setObjLocation] = useState(null);

  let history = useHistory();

  const convertDateZero = (date) => {
    let _Date = new Date(date);
    let timeOffcet = _Date.getTimezoneOffset() * 60000;
    let finalDate = new Date(_Date.getTime() - timeOffcet).toISOString();
    return finalDate;
  };

  const showPosition = async (position, _type) => {
    try {
      // const locat = JSON.parse(localStorage.getItem("sendLocation"));
      // message.info(`(accuracy: ${locat ? position.coords.accuracy : undefined}) |
      // (latitude: ${locat ? position.coords.latitude : undefined}) |
      // (longitude: ${locat ? position.coords.longitude : undefined}) |
      // (timestamp: ${position.timestamp})`)
      if (
        isValidLocation &&
        checkState(position.coords.accuracy, position.timestamp)
      ) {
        const locat = JSON.parse(localStorage.getItem("sendLocation"));
        let tempObj = {
          type: _type,
          attDate: convertDateZero(dateTimeNow(), false),
          latitude: locat ? position.coords.latitude : undefined,
          longitude: locat ? position.coords.longitude : undefined,
          location: "",
          workPlace: objLocation?.name,
          workPlaceId:objLocation?.id
        
        };
        const { data: data } = await saveAttLog(tempObj);
        if (data.code === 200) {
          message.success(data.message, 3);
          await setRegState("yes");
          setTimeout(() => {
            setRegState("");
            history.push({ pathname: "/orders" });
            setLoadIN(false);
            setLoadOut(false);
          }, 3500);
        }
      } else {
        await setRegState("no");
        setTimeout(() => {
          setRegState("");
          setLoadIN(false);
          setLoadOut(false);
        }, 3500);
        message.error(_t("strCannotGetYourLocation"));
      }
    } catch (error) {
      await setRegState("no");
      handleEx(error);
      setTimeout(() => {
        setRegState("");
        setLoadIN(false);
        setLoadOut(false);
      }, 1500);
    }
  };

  const getLocation = (type) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (e) => {
          showPosition(e, type);
        },
        showError,
        {
          enableHighAccuracy: true,
          timeout: Infinity,
          maximumAge: 0,
        }
      );
    } else {
      message.error(_t("strGeolocationNotSupportedByThisBrowser"), 5);
    }
  };

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message.error(_t("strBrowserDoesNotHaveAbilityLocation"), 5);
        break;
      case error.POSITION_UNAVAILABLE:
        message.error(_t("strGeoLocationInformationNotAvailable"), 5);
        break;
      case error.TIMEOUT:
        message.error(_t("strRequestLocationTimedOut"), 5);
        break;
      case error.UNKNOWN_ERROR:
        message.error(_t("msgUnknownError"), 5);
        break;
    }
    setLoadIN(false);
    setLoadOut(false);
  }

  const callGeolocation = (type) => {
    getLocation(type);
  };

  const checkState = (accuracy, timestamp) => {
    var nowTs = Date.now();
    var minTs = nowTs - 1000 * 60;
    var maxTs = nowTs + 1000 * 60;
    if (accuracy > 100 || timestamp < minTs || timestamp > maxTs) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card
      style={{
        marginTop: 15,
        textAlign: "center",
        width: "fit-content",
        margin: "auto",
      }}
    >
      {localStorage.getItem("attLogType") === "2" && (
        <ValidateLocation
          onHandleData={(value) => {
            setIsValidLocation(value);
          }}
          handleObjLocation={(value) => {
            
            setObjLocation(value);
          }}
        />
      )}

      <div className="mapLocation">
        <Spin spinning className="childSpin" size="large" />
        {regState === "" && (
          <img src={map} alt="loading..." className="mapLocation" />
        )}
        {regState === "no" && (
          <img src={no} alt="loading..." className="mapLocation" />
        )}
        {regState === "yes" && (
          <img src={yes} alt="loading..." className="mapLocation" />
        )}
      </div>
      <br />
      <Space>
        <Button
          type="primary"
          className="inOutButton"
          onClick={() => {
            setLoadIN(true);
            callGeolocation(0);
          }}
          //  icon={<LoginOutlined style={{ fontSize: 24 }} />}
          loading={loadIN}
          disabled={loadOut || !isValidLocation}
        >
          {_t("strPresence")}
        </Button>

        <Button
          type="primary"
          className="inOutButton"
          onClick={() => {
            setLoadOut(true);
            callGeolocation(1);
          }}
          // icon={<LogoutOutlined style={{ fontSize: 24 }} />}
          loading={loadOut}
          disabled={loadIN || !isValidLocation}
          danger
        >
          {_t("strDeparture")}
        </Button>
      </Space>
    </Card>
  );
};

export default PageRegistration;
