
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/store";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getStoreById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editStore(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}


export function deleteStore(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getStores() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}


export function getActiveStore(search) {
  const params = new URLSearchParams()
  search && params.append("search", search);  
 return `${apiEndpoint}/GetActiveChildren/?${params.toString()}`;
}


export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function GetActiveStoreChildren(obj) {
  return http.post(`${apiEndpoint}/GetActiveChildren`,obj);
}

export function getAllStore() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getActiveTreeStore(obj) {
  return http.post( `${apiEndpoint}/GetActiveTree`,obj);
}

export function getActiveTreeStoreRep(obj) {
  return http.post( `${apiEndpoint}/GetActiveTreeRep`,obj);
}




export function getActiveChildren(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveChildren/?search=${search}&addId=${addId}`
  );
}

export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}


export function getNewStoreCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerStore(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

