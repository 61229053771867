import { useState, useReducer, useEffect, useMemo } from "react";
import {
  Button,
  Divider,
  Pagination,
  Row,
  Col,
  message,
  Popover,
  Spin,
} from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableHeaderRenderer from "./components/DraggableHeaderRenderer";
import DataGrid from "react-data-grid";
import TreeFormatter from "./components/treeFormatter";
import { reducer } from "./components/reducer";
import VisibleCol from "./components/visibleCol";
import _t from "../../../languages/translate";
import {
  ColumnHeightOutlined,
  VerticalAlignMiddleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import PathText from "./components/pathText";
import { useHistory, Link } from "react-router-dom";
import TableEmpty from "./components/tableEmpty";
import {
  getTableSetting,
  postTableSetting,
} from "../../../services/tabeSettService";
import { handleEx } from "../handleException";

export const pageKey = "pageNumber",
  pageSizeKey = "pageSize",
  tablePageSizes = [
    "10",
    "20",
    "50",
    "100",
    "200",
    "500",
    "1000",
    "10000",
    "50000",
    "100000",
  ],
  searchKey = "search";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1~ (( columns )) → → → Columns name.
// 2~ (( dataSource )) → → → Data.
// 3~ (( tableName )) → → → The name of the table is used for storage at the user level in the event of modification to the display, arrangement and hiding of columns.
// 4~ (( tree )) → → → If the table is of a tree type, a value is sent : (bool) .
// 5~ (( colExp )) → → → The location of the opening and closing button for the table is determined by sending the column number starting from : (0).
// 6~ (( frezzColExp )) → → → Fixing the position of the opening and closing column in the table. A value is sent : (bool).
// 7~ (( actionButtons )) → → → action buttons like "Add New", "Search", "Print" ....
// 8~ (( meta )) → → → information about pagenation table : "pageNumber", "totalRecords", "pageSize".
// 9~ (( currentData )) → → → to get orgenal data source from reducer.
// 10~ (( isPagination )) → → → for hide pagination.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const DataTable = ({
  dataSource = [],
  tree = false,
  tableName = "",
  columns = [],
  showCustomTable = true,
  showPath = true,
  showTableButtons = true,
  showActionButtons = true,
  meta = { pageNumber: 0, totalRecords: 0, pageSize: 0 },
  cIndexExp = 1,
  cNameExp = null,
  pageName = "Unknow",
  branchName = "Unknow",
  groupName = "Unknow",
  type = "page",
  frezzColExp = false,
  actionButtons = <></>,
  currentData,
  isPagination = true,
  summary,
  handleReload,
  reloadState,
  showReload = true,
  reportFilter = null,
  loading = false,
  ...rest
}) => {
  const [rows, dispatch] = useReducer(reducer, dataSource);
  const [expantState, setExpandState] = useState(true);
  let history = useHistory();
  const [renderColumns, setRenderColumns] = useState([]);

  //**.start >> Drawing links ********** **********

  const linksColumns = [
    {
      key: "rowNum",
      name: "#",
      minWidth: 30,
      width: 43,
      formatter: (p) => {
        return p?.row?.reportTempMenu?.length !== 0 &&
          p?.row?.reportTempMenu?.length !== undefined ? (
          <Popover
          
            placement="bottomRight"
            trigger="click"
            style={{ width: "100%" }}
            content={() => linkCont(p?.row)}
          >
            <label className="keyNum" style={{width: "100%", color: "#1d98f4", textDecoration: "underline" }}>
              {p?.row?.rowNum}
            </label>
          </Popover>
        ) : (
          <label className="keyNum" style={{width: "100%", fontSize: 10, fontWeight: 600 }}>
            {p?.row?.rowNum}
          </label>
        );
      },
    },
  ];

  const linkCont = (item) => (
    <div>
      {item?.reportTempMenu.map((e) =>
        e?.type === 0 ? (
          <p key={e?.key}>
            <Link target="_blank" rel="noopener noreferrer" to={e?.url}>
              <label style={{ cursor: "pointer" }}>{e?.name}</label>
            </Link>
          </p>
        ) : (
          <p key={e?.key}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={e?.url}
              onClick={() => handleLinkObj(item, e?.reportKey)}
            >
              <label style={{ cursor: "pointer" }}>{e?.name}</label>
            </Link>
          </p>
        )
      )}
    </div>
  );

  const handleLinkObj = (item, repKey) => {
    let obj = {
      reportFilter: reportFilter,
      row: JSON.stringify(item),
      reportKey: repKey,
      timeShift: new Date().getTimezoneOffset(),
    };
    localStorage.setItem("linkObj", JSON.stringify(obj));
  };
  //**.end >> Drawing links ********************

  const toggleCol = (colKey, visible) => {
    let nextCols = [];
    if (colKey === "all") {
      nextCols = [...renderColumns].map((c) => (c = { ...c, visible }));
    } else {
      nextCols = [...renderColumns].map((c) =>
        c.key === colKey ? { ...c, visible } : c
      );
    }
    setRenderColumns(nextCols);
  };

  const draggableColumns = useMemo(() => {
    const HeaderRenderer = (props) => {
      return (
        <DraggableHeaderRenderer
          {...props}
          onColumnsReorder={handleColumnsReorder}
        />
      );
    };

    const handleColumnsReorder = (sourceKey, targetKey) => {
      const sourceColumnIndex = renderColumns.findIndex(
        (c) => c.key === sourceKey
      );
      const targetColumnIndex = renderColumns.findIndex(
        (c) => c.key === targetKey
      );
      const reorderedColumns = [...renderColumns];
      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );
      setRenderColumns(reorderedColumns);
    };

    return renderColumns.map((c, i) => {
      if (frezzColExp === true && i === cIndexExp) return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [renderColumns]);

  const filteredCols = () => {
    const result = draggableColumns.filter(
      (c) => typeof c.visible !== "boolean" || c.visible
    );
    localStorage.setItem(tableName, JSON.stringify(result));
    return result;
  };

  // resize ~~~~~~~~~~~~~~~~~~~~~~~~

  let rtime;
  let timeout = false;
  let delta = 200;
  let _index = 0;
  let _width = 0;
  const handleColumnResize = (idx, width) => {
    let exColCount = 1;
    if (tree) exColCount++;
    if (idx < exColCount) return;
    _index = idx - exColCount;
    _width = width;
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resize, delta);
    }
  };

  const resize = () => {
    if (new Date() - rtime < delta) {
      setTimeout(resize, delta);
    } else {
      timeout = false;
      renderColumns[_index].width = _width;
      localStorage.setItem(tableName, JSON.stringify(renderColumns));
    }
  };

  const handleSaveColumns = async () => {
    let obj = {
      tableName: tableName,
      tableState: JSON.stringify(renderColumns),
    };
    try {
      const { data: res } = await postTableSetting(obj);
      if (res?.code === 200) {
        message.success(res?.message);
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleGetColumns = async () => {
    try {
      let newArr = [];
      const { data: res } = await getTableSetting(tableName);
      if (res?.data !== null) {
        let arrCol = JSON.parse(res?.data?.tableState);
        for (let i = 0; i < arrCol.length; i++) {
          let item = columns.find((e) => {
            if (e.key === arrCol[i].key) {
              e.width = arrCol[i].width;
              e.visible =
                arrCol[i]?.visible !== true ? arrCol[i].visible : true;
              return e;
            }
          });
          if (item !== undefined) newArr.push(item);
        }
        columns.filter((e) => {
          if (newArr.find((i) => i.key === e.key) === undefined) {
            newArr.push(e);
          }
        });
      }
      let col = newArr.length > 0 ? newArr : columns;

      setRenderColumns(col);
    } catch (error) {
      handleEx(error);
    }
  };

  // resize ~~~~~~~~~~~~~~~~~~~~~~~~

  useEffect(() => {
    handleGetColumns();
  }, [columns.length]);

  useEffect(() => {
    dispatch({ data: dataSource });
    setExpandState(true);
  }, [dataSource]);

  useEffect(() => {
    if (currentData !== undefined) {
      currentData(rows);
    }
  }, [rows]);

  const tableButtons = (
    <div className="tableButtons" style={{ direction: _t("langDiriction") }}>
      {tree && (
        <>
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              dispatch({ type: expantState ? "expand" : "unExpand" });
              setExpandState(!expantState);
            }}
            icon={
              expantState ? (
                <ColumnHeightOutlined />
              ) : (
                <VerticalAlignMiddleOutlined />
              )
            }
          />
          <Divider type="vertical" />
        </>
      )}
      {showReload && (
        <>
          <Button
            type="text"
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={handleReload}
            disabled={reloadState}
          />
          <Divider type="vertical" />
        </>
      )}
      <VisibleCol
        columns={renderColumns}
        toggleCol={toggleCol}
        tableName={tableName}
        direction={_t("langDiriction")}
        handleSaveColumns={handleSaveColumns}
      />
    </div>
  );

  const handlePagination = (current, pageSize) => {
    const query = new URLSearchParams(history.location.search);
    if (current && current > 1) query.set(pageKey, current.toString());
    else query.delete(pageKey);
    if (pageSize) query.set(pageSizeKey, pageSize.toString());
    else query.delete(pageSizeKey);
    history.push({
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  const finalRenderCols = () => {
    if (rows.length > 0) {
      let column = filteredCols().map((c) =>
        c.align === "Right"
          ? {
              ...c,
              formatter: (p) => {
                return <label className="cellNumber"> {p.row[c.key]}</label>;
              },
            }
          : c
      );
      const maxLevel = Math.max(...rows.map((e) => e.level));
      const width = 20 * (maxLevel + 1) + 10;

      let treeEx = {
        key: "expand",
        name: "",
        minWidth: 30,
        width: width,
        visible: true,
        cellClass() {
          return "treeStype";
        },
        formatter: (row, isCellSelected) => {
          return (
            <TreeFormatter
              row={row}
              obj={{ key: "exp" }}
              isCellSelected={isCellSelected}
              dispatch={dispatch}
              maxLevel={maxLevel}
            />
          );
        },
      };
      return tree
        ? [treeEx, ...linksColumns, ...column]
        : [...linksColumns, ...column];
    } else {
      return [];
    }
  };

  return (
    <Spin tip={_t("strLoading")} size="large" spinning={loading}>
      {showPath && (
        <PathText
          pageName={pageName}
          branchName={branchName}
          groupName={groupName}
          type={type}
        />
      )}
      <div className="tableContener_middle">
        {showCustomTable && (
          <Row>
            {showActionButtons && <Col span={12}>{actionButtons}</Col>}
            {showTableButtons && <Col span={12}>{tableButtons}</Col>}
          </Row>
        )}

        <DndProvider backend={HTML5Backend}>
          <DataGrid
            {...rest}
            rowHeight={25}
            components={{ noRowsFallback: <TableEmpty /> }}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onColumnResize={handleColumnResize}
            columns={finalRenderCols()}
            rows={rows.map((e, i) => (e = { ...e, rowNum: i + 1 }))}
            rowClass={(row) => {
              switch (row.rowType) {
                case "Main":
                  return row.rowNum % 2 === 0
                    ? "mainRow table-row-light"
                    : "mainRow table-row-dark";
                case "Patt":
                  return row.rowNum % 2 === 0
                    ? "mainRow table-row-light"
                    : "mainRow table-row-dark";
                case "IN":
                  return row.rowNum % 2 === 0
                    ? "inRow table-row-light"
                    : "inRow table-row-dark";
                case "Out":
                  return row.rowNum % 2 === 0
                    ? "outRow table-row-light"
                    : "outRow table-row-dark";
                case "PrevBalance":
                  return row.rowNum % 2 === 0
                    ? "prevBalanceRow table-row-light"
                    : "prevBalanceRow table-row-dark";
                case "TermBalance":
                  return row.rowNum % 2 === 0
                    ? "tremBalanceRow table-row-light"
                    : "tremBalanceRow table-row-dark";
                case "FinalBalance":
                  return row.rowNum % 2 === 0
                    ? "finalBalanceRow table-row-light"
                    : "finalBalanceRow table-row-dark";
                case "Trans":
                  return row.rowNum % 2 === 0
                    ? "transRow table-row-light"
                    : "transRow table-row-dark";
                case "DifBalance":
                  return row.rowNum % 2 === 0
                    ? "difBalanceRow table-row-light"
                    : "difBalanceRow table-row-dark";
                case "Child":
                  return row.rowNum % 2 === 0
                    ? "childRow table-row-light"
                    : "childRow table-row-dark";
                case "PattSett":
                  return row.rowNum % 2 === 0
                    ? "childRow table-row-light"
                    : "childRow table-row-dark";
                default:
                  return row.rowNum % 2 === 0
                    ? "table-row-light"
                    : "table-row-dark";
              }
            }}
            className="rdg-light"
            direction={_t("langDiriction")}
            summaryRows={summary}
          />
        </DndProvider>
      </div>

      {isPagination && (
        <div className="tableContener_footer">
          <Pagination
            showQuickJumper
            current={meta?.pageNumber}
            total={meta?.totalRecords}
            onChange={handlePagination}
            defaultCurrent={meta?.pageNumber}
            showTotal={(total) => _t("strTotal") + " : " + `${total}`}
            showSizeChanger={true}
            hideOnSinglePage={false}
            size="small"
            pageSizeOptions={tablePageSizes}
            rowClass={(row) => {
              return row.rowNum % 2 === 0
                ? "table-row-light"
                : "table-row-dark";
            }}
          />
        </div>
      )}
    </Spin>
  );
};
export default DataTable;
