//import "./style.css"
import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { useHistory } from "react-router-dom";
import _t from "../../../languages/translate";
// import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../../../services/Authorization/authService";
import Image from "../../../images/jpeg/login.jpg";
// import Avatar from "../../../images/svg/Logo/YBLogoTxt.svg";
import { useState } from "react";

const PageLogin = () => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
  };
  const [loadings, setLoadings] = useState(false);
  let history = useHistory();

  const onFinish = async (values) => {
    setLoadings(true);
    try {
      await login(values.userName, values.password);
      //  window.localStorage.setItem("user", values.userName)
      const { state } = history.location;
      window.history.replaceState(null, null, `${process.env.PUBLIC_URL}/`);
      window.location = state
        ? state.from.pathname
        : `${process.env.PUBLIC_URL}/`;
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadings(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadings(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadings(false);
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-box">
      
    
      <Form
          name="login-form"
          style={{direction:_t("langDiriction")}}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <p className="form-title" >
            {_t("strWeAre")+" "} <span style={{ color: "black" ,fontSize: 36}}> {_t("strYottaESS")}</span>
          </p>
          <p>{_t("strWelcomeBack")}</p>

          <Form.Item
            name="userName"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input style={{ borderRadius: 6 }} placeholder={_t("strUserName")} />
          </Form.Item>
          <br />
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              size="small"
              style={{ borderRadius: 6 }}
              placeholder={_t("strPassword")}
            />
          </Form.Item>

          <br />
          {/* <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loadings}
            >
              {_t("strLogin")}
            </Button>
          </Form.Item>
        </Form>
       
        <div className="illustration-wrapper">       
          <img src={Image} alt="Login" />
        </div>
     
      </div>
    </div>
  );
};

export default PageLogin;
