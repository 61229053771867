import jwtDecode from "jwt-decode";
import http, { setJwt } from "../http";
import { getDefaultIdByCompany } from "../Items/currenciesService";
import { getCurrUser } from "../EmpService";
// import { getAuth } from "./userRoleService";

const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/Auth`;
const LangJwt = "lang";
const companyIdJwt = "comId";
const currIdJwt = "currId";
const tokenKey = "token";
const refreshTokenKey = "refreshToken";
const authName = "auth";

export const permissions = {};

export function getRolesJwt() {
  try {
    const jwt = getJwt(tokenKey);
    const { rol } = jwtDecode(jwt);
    const ArrayData = rol.split(",");

    for (var i = 0; i < ArrayData.length; i++) {
      const supp = ArrayData[i];
      permissions[supp] = true;
    }
    
  } catch (ex) {
    return null;
  }
}

setJwt(getJwt());

export async function login(userName, password) {
  
  const { data } = await http.post(`${apiEndpoint}/login`, {
    userName,
    password,
  });

  saveJwt(data.token);
  saveRefreshJwt(data.refreshToken);
  saveLangJwt();
  saveCompanyId();
  
  await saveCurrId();
  const { data: res } = await getCurrUser();
  window.localStorage.setItem("user",res?.data?.name);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(companyIdJwt);
  localStorage.removeItem(currIdJwt);
  localStorage.removeItem(authName);
  localStorage.removeItem("user");
  sessionStorage.clear();
}

export function getCurrentUser() {
  try {
    const jwt = getJwt(tokenKey);
    const user = jwtDecode(jwt);
    //if (user.exp < Date.now() / 1000) return null
    return user;
  } catch (ex) {
    return null;
  }
}

export async function refreshJwt() {
  const tokenObj = {
    token: getJwt(),
    refreshToken: getRefreshJwt(),
  };
  try {
    const refreshResponse = await http.post(
      `${apiEndpoint}/refreshtoken`,
      tokenObj
    );
    setJwt(refreshResponse.data.token);
    saveJwt(refreshResponse.data.token);
    saveRefreshJwt(refreshResponse.data.refreshToken);
    return refreshResponse;
  } catch (err) {
    return err;
  }
}

export async function refreshToken() {
  const tokenObj = {
    token: getJwt(),
    refreshToken: getRefreshJwt(),
  };

  try {
    const result = await http.post(`${apiEndpoint}/refreshtoken`, tokenObj);

    saveJwt(result.data.token);
    saveRefreshJwt(result.data.refreshToken);
    setJwt(getJwt());
    return result;
  } catch (err) {
    return err;
  }
}

export function saveCompanyId() {
  try {
    const jwt = getJwt(tokenKey);
    const { companyId } = jwtDecode(jwt);
    return localStorage.setItem(companyIdJwt, companyId);
  } catch (ex) {
    return null;
  }
}
async function saveCurrId() {
  try {
    // const jwt = getJwt(tokenKey);
    // const { companyId } = jwtDecode(jwt);
    // const { data } = await getDefaultIdByCompany(companyId);

    // return localStorage.setItem(currIdJwt, data.data);
  } catch (ex) {
    return null;
  }
}

export function saveLangJwt() {
  try {
    const jwt = getJwt(tokenKey);
    const { lang } = jwtDecode(jwt);
    return localStorage.setItem(LangJwt, lang);
  } catch (ex) {
    return null;
  }
}

export function saveJwt(token) {
  return localStorage.setItem(tokenKey, token);
}

export function saveRefreshJwt(refreshToken) {
  return localStorage.setItem(refreshTokenKey, refreshToken);
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getRefreshJwt() {
  return localStorage.getItem(refreshTokenKey);
}

export async function authFromServer() {
  try {
    // const data = await getAuth();
    // return localStorage.setItem(authName, JSON.stringify(data.data.data));
  } catch (ex) {
    return null;
  }
}

const auth = {
  login,
  logout,
  getCurrentUser,
  getRolesJwt,
  getJwt,
};

export default auth;
