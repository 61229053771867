import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _t from "../../../languages/translate";
import {
  Button,
  Card,
  Divider,
  List,
  message,
  Space,
  Typography,
  Spin,
  Row,
  Col
} from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  FilterOutlined,
  UserSwitchOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { handleEx } from "../../helper/handleException";
import { _content, _extra, _title, _tag, _pageSizeKey } from "./healper";
import { dateTimeNow } from "../../helper/helperMethods";
import {
  getPagerAttLogResMgr
} from "../../../services/AttLogResMgrService";
import { editAttLogResMgr } from "../../../services/AttLogResMgrService";
import FilterManageRegestration from "./FilterManageRegestration";

const PageManageRegestration = () => {
  const savedPageSize = localStorage.getItem(_pageSizeKey);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  let location = useLocation();
  const { Title } = Typography;
  const [viewObj, setViewObj] = useState({
    reqTypes: [],
    statuses: [0],
  });


  useEffect(() => {
    getView(viewObj);
  }, [location.search, savedPageSize]);

  const getView = async (obj) => {
    try {
      await setLoading(true);
      const query = new URLSearchParams(location.search);
      if (savedPageSize && !query.has(_pageSizeKey))
        query.set(_pageSizeKey, savedPageSize);
        const { data: responce } = await getPagerAttLogResMgr(
        query.toString(),
        obj
      );
      
      if (responce.data.length > 0) {
        setDataSource(responce?.data);
      } else {
        setDataSource([]);
        message.info(_t("msgThereIsNoData"), 3);
      }
      await setLoading(false);
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const convertDateZero = (date) => {
    let _Date = new Date(date);
    let timeOffcet = _Date.getTimezoneOffset() * 60000;
    let finalDate = new Date(_Date.getTime() - timeOffcet).toISOString();
    return finalDate;
  };

  const replaceObjById = (array, data) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === data.id) {
        array[i] = { ...data };
      }
    }
    return array;
  };

  const getById = async (id, type) => {
    await setLoading(true);
    try {
      let _obj = {
        isApp: type,
        date: convertDateZero(dateTimeNow(), false),
        attLogId: id,
      };
      const { data: data } = await editAttLogResMgr(_obj);
      if (data?.code === 200) {
        replaceObjById(dataSource, data.data)
        message.success(data?.message, 5);
        await setLoading(false);
      }
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const onHandleFilterData = (obj) => {
    setViewObj(obj);
    getView(obj);
    setShowFilterModal(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <div className="contentList">
        <FilterManageRegestration
          showModal={showFilterModal}
          setShowModal={setShowFilterModal}
          onHandleData={onHandleFilterData}
        />
          <Row>
          <Col style={{ textAlign: "start" }} span={16}>
        <Space split={<Divider type="vertical" />}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setShowFilterModal(true);
            }}
            icon={<FilterOutlined />}
          >
            {_t("strSearch")}
          </Button>
          <Title level={5}>
            <UserSwitchOutlined style={{ fontSize: 16 }} /> {_t("strManageAtt")}
          </Title>
        </Space>

        </Col>

<Col style={{ textAlign: "end" }} span={8}>
  <Button
    type="link"
    size="large"
    style={{ marginLeft: 10, marginRight: 10 }}
    onClick={() => {
      getView(viewObj);
    }}
    icon={
      <ReloadOutlined style={{ fontSize: 20, color: "#707070" }} />
    }
  />
</Col>
</Row>
        <Divider style={{ margin: 5 }} />
        <div
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "86vh",
            alignContent: "center",
          }}
        >
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={dataSource}
            renderItem={(e) => (
              <List.Item>
                <Card
                  title={_title(e)}
                  headStyle={{ padding: "0 12px" }}
                  bodyStyle={{ padding: 5 }}
                  actions={[
                    <CloseOutlined
                      key="reject"
                      style={{ fontSize: 16 }}
                      onClick={() => getById(e.id, false)}
                    />,
                    <CheckOutlined
                      key="accept"
                      style={{ fontSize: 16 }}
                      onClick={() => getById(e.id, true)}
                    />,
                  ]}
                >
                  {_content(e)}
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Spin>
  );
};

export default PageManageRegestration;
