export const dateFormatList = [

    "DD / MM / YYYY  HH:mm",

    "D/M/YYYY H:m",
    "D/M/YYYY H/m",
    "D/M/YYYY/H:m",
    "D/M/YYYY/H/m",
    "D/M/YYYY/H",
    "D/M/YYYY H",
    "D/M/YYYYH",
    "D/M/YYYY",

    "D/M/YY H:m",
    "D/M/YY H/m",
    "D/M/YY/H:m",
    "D/M/YY/H/m",
    "D/M/YY/H",
    "D/M/YY H",
    "D/M/YY",

    "D/M",
    "D/M H",
    "D/M H:m",
    "D/M H/m",

    "D",
    "D H",
    "D H:m",
    "D H/m",

    "D-M-YYYY H:m",
    "D-M-YYYY H-m",
    "D-M-YYYY-H:m",
    "D-M-YYYY-H-m",
    "D-M-YYYY-H",
    "D-M-YYYY H",
    "D-M-YYYYH",
    "D-M-YYYY",

    "D-M-YY H:m",
    "D-M-YY H-m",
    "D-M-YY-H:m",
    "D-M-YY-H-m",
    "D-M-YY-H",
    "D-M-YY H",
    "D-M-YY",

    "D-M",
    "D-M H",
    "D-M H:m",
    "D-M H-m",

    "D H-m",

    "D M YYYY H:m",
    "D M YYYY H m",
    "D M YYYY H",
    "D M YYYYH",
    "D M YYYY",

    "D M YY H:m",
    "D M YY H m",
    "D M YY H",
    "D M YY",

    "D M",
    "D M H:m",

    "DMYYYY H:m",
    "DMYYYY Hm",
    "DMYYYYH:m",
    "DMYYYYHm",
    "DMYYYYH",
    "DMYYYY H",
    "DMYYYY",

    "DMYY H:m",
    "DMYY Hm",
    "DMYYH:m",
    "DMYYHm",
    "DMYY H",
    "DMYY",

    "DM",
    "DM H",
    "DM H:m",
    "DM Hm",

    "D Hm",

    "D.M.YYYY H:m",
    "D.M.YYYY H.m",
    "D.M.YYYY.H:m",
    "D.M.YYYY.H.m",
    "D.M.YYYY.H",
    "D.M.YYYY H",
    "D.M.YYYYH",
    "D.M.YYYY",

    "D.M.YY H:m",
    "D.M.YY H.m",
    "D.M.YY.H:m",
    "D.M.YY.H.m",
    "D.M.YY.H",
    "D.M.YY H",
    "D.M.YY",

    "D.M",
    "D.M H",
    "D.M H:m",
    "D.M H.m",

    "D H.m",

];

export const dateFormatListUnTime = [
    "DD / MM / YYYY",
    "D/M/YY",
    "D/M",
    "D",
    "D-M-YY",
    "D-M",
    "D M YY",
    "D M",
    "DMYY",
    "DM",
    "D.M.YY",
    "D.M",
];