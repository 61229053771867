
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Client";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getClient(search) {
  return http.post(`${apiEndpoint}/GetActiveTree/?search=${search}`);
}

export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}


export function getClientById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getClientActiveSearch(obj) {
  return http.post(
    `${apiEndpoint}/GetActiveChildren`, obj
  );
}

export function editClient(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

export function getActiveTreeClient(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveTree/?search=${search}&addId=${addId}`
  );
}

export function getActiveTreeRepClient(obj) {
  return http.post(`${apiEndpoint}/GetActiveTreeRep`,obj);
}


export function deleteClient(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getNewClientCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}
