import React from "react";
import { Input } from "antd";
import { emptValue } from "../../helper/helperMethods";
const InputCurrency = ({
  innerRef,
  precision = 3,
  value,
  max = 100000000000000,
  min = -100000000000000,
  cutRestVal = false,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [focus, setFocus] = React.useState(false);
  const [orginalVal, setOrginalVal] = React.useState("");
  const handleChange = (e) => {
    const n1 = parseFloat(e.target.value);

    if ((n1 <= max && n1 >= min) || e.target.value === "") {
      const val = e.target.value.replace(/[^\d.-]/g, "");
      onChange && onChange(val);
    } else if (isNaN(e.target.value)) {
      const val = e.target.value.replace(/[^\d.-]/g, "");
      onChange && onChange(val);
    }
  };

  const handleFocus = (e) => {
    setOrginalVal(e.currentTarget.value);
    setFocus(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    setFocus(false);

    const n1 = parseFloat(formatedNumber(e.target.value, false));
    const n2 = parseFloat(formatedNumber(orginalVal, false));

    if (n1 !== n2 && e.target.value !== "") {
      if (cutRestVal) {
        let val = formatedNumber(value, false);
        onChange && onChange(!!val ? parseFloat(val) : val);
      } else {
        onChange && onChange(!!value ? parseFloat(value) : value);
      }
      onBlur && onBlur(e);
    }
  };

  const formatedNumber = (val, withComma = true) => {
    if (
      (typeof val !== "string" && typeof val !== "number") ||
      val === undefined
    )
      return null;

    val = parseFloat(val).toFixed(precision);
    let results = `${val}`.replace(/[^\d.-]/g, "").split(".");
    if (!results[0]) {
      return null;
    }
    if (withComma) {
      results[0] = results[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (results[0] && typeof precision === "number" && precision > 0) {
      results[1] =
        results[1] && results[1].lenght === precision
          ? results[1]
          : fillNumber(results[1], precision);
      results = results.splice(0, 2);
      let val = results.join(".");
      return parseFloat(val) === 0 ? undefined : val;
    }

    return results[0];
  };

  // formatter and parser input number
  //  const formatterNumber = (val) => {
  //   if (!val) return 0;
  //   return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
  // }

  //  const parserNumber = (val) => {
  //   if (!val) return 0;
  //   return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2)
  // }

  return (
    <Input
      ref={innerRef}
      value={
        focus
          ? value !== null && value !== null && value !== NaN
            ? value
            : undefined
          : formatedNumber(value)
      }
      onChange={handleChange}
      onFocus={handleFocus}
      //maxLength='15'
      onBlur={handleBlur}
      autoComplete="off"
      // onPressEnter={handleBlur}
      style={{ width: 500 }}
      // formatter={(value) => formatterNumber(value)}
      // parser={(value) => parserNumber(value)}
      {...rest}
    />
  );
};

export default InputCurrency;

function fillNumber(value, decimals) {
  if (!decimals || decimals === 0) {
    return "";
  }
  const values = (value || "").split("");
  let results = [];
  for (let i = 0; i < decimals; i++) {
    results[i] = values[i] || "0";
  }
  return results.join("");
}
