import _t from "../../../languages/translate";
import { emptString } from "../../helper/helperMethods";
import { Divider, Space, Tag, Typography } from "antd";

const { Text } = Typography;

export const _titleMgr = (e) => (
  <Space wrap size="small" className="spaceRes">
  <Text className="title" strong>{e?.mgrName}</Text> 
      {_tagMgr(e)}
    </Space>
  );

  //export const _extraMgr = (e) => <Text strong>{e?.applyDate}</Text>;

  export const _tagMgr = (e) => (
    <Tag color={e?.isApp ? "green" : "red"}>
      {e?.isApp
        ? _t("strAccepted") +" : "+ e?.date
        : _t("strRejected")+" : "+ e?.date
        
        }
    </Tag>
  );

  export const _contentMgr = (e) => (
    <Space direction="vertical">
      <Space size="small" wrap split={<Divider type="vertical" />}>
        {emptString(e?.empName, false) && (
          <Text type="secondary">
            {_t("strName")} : <Text>{e?.empName}</Text>
          </Text>
        )}
        {emptString(e?.timeTypeName, false) && (
          <Text type="secondary">
            {_t("strType")} : <Text mark>{e?.timeTypeName}</Text>
          </Text>
        )}
        {emptString(e?.salaryAdvTypeName, false) && (
          <Text type="secondary">
            {_t("strType")} : <Text mark>{e?.salaryAdvTypeName}</Text>
          </Text>
        )}
        {emptString(e?.errandVacationName, false) && (
          <Text type="secondary">
            {_t("strType")} : <Text mark>{e?.errandVacationName}</Text>
          </Text>
        )}
        {emptString(e?.value, false) && (
          <Text type="secondary">
            {_t("strAmount")} : <Text mark>{e?.value}</Text>
          </Text>
        )}

        {emptString(e?.date, false) && (
          <Text type="secondary">
            {_t("strDate")} : <Text>{e?.applyDate}</Text>
          </Text>
        )}

        {emptString(e?.applyFromDate, false) && (
          <Text type="secondary">
            {_t("strFrom")} : <Text>{e?.applyFromDate}</Text>
          </Text>
        )}

        {emptString(e?.applyToDate, false) && (
          <Text type="secondary">
            {_t("strTo")} : <Text>{e?.applyToDate}</Text>
          </Text>
        )}
      </Space>
      {emptString(e?.note, false) && (
        <Text type="secondary">
          {_t("strNote")} : <Text>{e?.note}</Text>
        </Text>
      )}
  
    </Space>
  );