
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/CostCenter";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getCostCenterById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editCostCenter(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteCostCenter(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getCostCenter(value) {
  return http.get(`${apiEndpoint}/?search=${value}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllCostCenter() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewCostCenterCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerCostCenter(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getActiveTreeRepCostCenters(obj) {
  return http.post(`${apiEndpoint}/GetActiveTreeRep`,obj);
}

export function searchCostCenter(code, name, isMatching) {
  return http.post(
    `${apiEndpoint}/GetChildrenActiveSearch?Code=${code}&Name=${name}&IsMatching=${isMatching}`
  );
}

export function getActiveTreeCostCenter(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveTree/?search=${search}&addId=${addId}`
  );
}

export function getSearchPopup(search) {
  return http.post(
    `${apiEndpoint}/GetSearchPopup/?q=${search}`
  );
}

export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}