import { Space, Tag, Divider, Typography } from "antd";
import{emptString}from "../../helper/helperMethods";

import _t from "../../../languages/translate";
const { Text } = Typography;

export let _data = {
  applyDate: "10/4/2023 6:53:05 AM", //
  applyFromDate: null, //
  applyToDate: null, //
  empName: null, // نسخة مدير
  errandVacationName: null, //
  id: "08dbc4a6-bce5-4f70-839e-478848f97f06", // برمجي
  reqAppTypeName: "يوم إضافي", //
  reqDate: "10/4/2023 6:51:12 AM", //
  resDate: "04/10/2023", //
  status: 0, //
  timeTypeName: null, //
  value: null, //
  note: null, //
};

export const _tag = (e) => (
  <Tag color={e?.status === 2 ? "green" : e?.status=== 1 ? "red" : e?.status === 3 ? "purple": "blue"}>
    {e?.status === 2
      ?  `${_t("strAccepted")} : ${e?.resDate}`
      : e?.status === 1
      ?  `${_t("strRejected")} : ${e?.resDate}`
      : e?.status === 3
      ?  `${_t("strApplied")} : ${e?.resDate}`
      : _t("strWaiting")}
  </Tag>
);

export const _title = (e) => (
  <Space wrap size="small" className="spaceRes">
   <Text className="title" strong>{e?.reqAppTypeName}</Text> 
    {_tag(e)}
  </Space>
);

export const _extra = (e) => <Text className="extra" strong>{e?.reqDate}</Text>;

export const _content = (e) => (
  <Space direction="vertical">
    <Space size="small" wrap split={<Divider type="vertical" />}>
      {emptString(e?.empName,false) && (
        <Text type="secondary">
          {_t("strName")} : <Text >{e?.empName}</Text>
        </Text>
      )}
      {emptString(e?.timeTypeName,false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.timeTypeName}</Text>
        </Text>
      )}
      {emptString(e?.errandVacationName,false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.errandVacationName}</Text>
        </Text>
      )}
         {emptString(e?.salaryAdvTypeName,false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.salaryAdvTypeName}</Text>
        </Text>
      )}
      {emptString(e?.value,false) && (
        <Text type="secondary">
          {_t("strAmount")} : <Text mark>{e?.value}</Text>
        </Text>
      )}

      {emptString(e?.applyDate,false) && (
        <Text type="secondary">
          {_t("strDate")} : <Text >{e?.applyDate}</Text>
        </Text>
      )}

      {emptString(e?.applyFromDate,false) && (
        <Text type="secondary">
          {_t("strFrom")} : <Text>{e?.applyFromDate}</Text>
        </Text>
      )}

      {emptString(e?.applyToDate,false) && (
        <Text type="secondary">
          {_t("strTo")} : <Text>{e?.applyToDate}</Text>
        </Text>
      )}
    </Space>
    {emptString(e?.note,false) && (
    <Text type="secondary">
      {_t("strNote")} : <Text>{e?.note}</Text>
    </Text>
    )}
  </Space>
);

export const _pageKey = "pageNumber",
  _pageSizeKey = "pageSize",
  _tablePageSizes = [
    "10",
    "20",
    "50",
    "100",
    "200",
    "500",
    "1000",
    "10000",
    "50000",
    "100000",
  ],
  _searchKey = "search";
