
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/TableSetting";

export function getTableSetting(name) {
  return http.get(`${apiEndpoint}/?name=${name}`);
}


export function postTableSetting(obj) {
  return http.post(apiEndpoint, obj);
}

