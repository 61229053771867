
import http from "../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/User";

export function getUsersById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function editUsers(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function setLanguage(obj) {
  return http.post(`${apiEndpoint}/${obj}`);
}

export function deleteUsers(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getAllUsers() {
  return http.get(`${apiEndpoint}`);
}


