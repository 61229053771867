import React, { useState } from "react";
import { Button, Popover, Checkbox, Divider } from "antd";
import { EyeOutlined, SettingOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";

const VisibleCol = ({ columns, toggleCol, tableName, direction ,handleSaveColumns}) => {



  const handleChange = (value, key) => {
    toggleCol(key, value)
  };

  const content =
    <>
      <div>
        <Checkbox defaultChecked onChange={(event) => { handleChange(event.target.checked, "all");}}>
        <strong >{_t("strAll")}</strong> 
        </Checkbox>
      </div>
      <Divider style={{margin: "5px 0"}}/>
      <div style={{ maxHeight: 400, overflowY: "scroll" }}>
        {columns.map((e) =>
          e.visible !== undefined ? (
            <div>
              <Checkbox
                checked={e?.forceVisible !== true? e.visible : true}
                disabled={e?.forceVisible === true}
                onChange={(event) => {
                  handleChange(event.target.checked, e.key);
                }}
              >
                {e.name}
              </Checkbox>
            </div>
          ) : null
        )}</div>
        <Divider style={{margin: "5px 0"}}/>
        <div>
      <Button type="link" onClick={handleSaveColumns}>
        حفظ كإفتراضي
      </Button>
      </div>
      
    </>

  return (
    <Popover
      content={content}
      trigger="click"
      placement={!direction ? "bottomRight" : "bottomLeft"}
    >
      <Button type="text" shape="circle" icon={<SettingOutlined />} />
    </Popover>
  );
};

export default VisibleCol;
