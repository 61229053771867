import { Empty } from 'antd';


const tableEmpty = () => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign:"center"
    }}>
      <Empty/>
    </div>
  );
}

export default tableEmpty;