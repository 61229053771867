import { toggleSubRow } from "./toggleSubRow";

export const reducer = (rows, {data,type, id }) => {
  const state = [...rows];
  
  switch (type) {
    case "toggleSubRow": {
      return toggleSubRow(state, "toggleSubRow", id);
    }
    case "expand": {
      return toggleSubRow(state, "expand");
    }
    case "unExpand": {
      return toggleSubRow(state, "unExpand");
    }
    default:
      if (Array.isArray(data)) {
        return data;
      } else {
        return rows;
      }
  }
};
