
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Journal";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getJournalById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

// export function getActiveJournal(obj) {
//   return http.post(`${apiEndpoint}/GetActive`, obj);
// }
export function getActiveJournal(search, addId) {
  return http.get( `${apiEndpoint}/GetActive/?search=${search}&addId=${addId}`);
}

export function editJournal(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllJournal() {
  return http.get(`${apiEndpoint}`);
}
export function deleteJournal(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerJournal(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewJournalCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
