import _t from "../../../languages/translate";
import { checkDate } from "../../helper/helperMethods";

export const convDate = (date, withZero) => {
    if (checkDate(date)) {
      if (withZero) {
        return new Date(date.startOf("day"));
      } else {
        return new Date(date);
      }
    } else {
      return undefined;
    }
  };



export const _titleByType = (t) =>
t === 1
  ? _t("strAbsence")
  : t === 2
  ? _t("strOneMoreDay")
  : t === 3
  ? _t("strDelay")
  : t === 4
  ? _t("strExtraTime")
  : t === 5
  ? _t("strRequestMission")
  : t === 6
  ? _t("strLeaveRequest")
  : _t("strAdvanceRequest");

  export const _options = [
{
  label: _t("strIn"),
  value: 0,
},
{
  label: _t("strOut"),
  value: 1,
},
];

export const _showByType = (type) => {
let obj = {
  showApplyDate: true,
  showTimeType: true,
  showErrandVacationId: true,
  showApplyFromDate: true,
  showApplyToDate: true,
  showValue: true,
  showSalaryAdvTypeId: true,
};

switch (type) {
  case 1:
    obj.showTimeType = false;
    obj.showErrandVacationId = false;
    obj.showApplyFromDate = false;
    obj.showApplyToDate = false;
    obj.showSalaryAdvTypeId = false;
    obj.showValue = false;
    return obj;
  case 2:
    obj.showTimeType = false;
    obj.showErrandVacationId = false;
    obj.showApplyFromDate = false;
    obj.showApplyToDate = false;
    obj.showSalaryAdvTypeId = false;
    obj.showValue = false;
    return obj;
  case 3:
    obj.showErrandVacationId = false;
    obj.showApplyFromDate = false;
    obj.showApplyToDate = false;
    obj.showSalaryAdvTypeId = false;
    obj.showValue = false;
    return obj;
  case 4:
    obj.showErrandVacationId = false;
    obj.showApplyFromDate = false;
    obj.showApplyToDate = false;
    obj.showSalaryAdvTypeId = false;
    obj.showValue = false;
    return obj;
  case 5:
    obj.showApplyDate = false;
    obj.showTimeType = false;
    obj.showValue = false;
    obj.showSalaryAdvTypeId = false;
    return obj;
  case 6:
    obj.showApplyDate = false;
    obj.showTimeType = false;
    obj.showSalaryAdvTypeId = false;
    obj.showValue = false;
    return obj;
  case 7:
    obj.showTimeType = false;
    obj.showErrandVacationId = false;
    obj.showApplyFromDate = false;
    obj.showApplyToDate = false;
    return obj;
}
};
