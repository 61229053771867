import React from "react";
import { TreeSelect, Form } from "antd";
import _t from "../../languages/translate";
import { validateTreeSelectFilter } from "../helper/helperMethods";

const SelectTreeMulty = ({
  initValue,
  name,
  noStyle = false,
  placeholder = _t("strNotSelected"),
  label,
  opt,
  state,
  setState,
  treeId,
  setTreeId,
  ...rest
}) => {
  const { SHOW_PARENT } = TreeSelect;
  const tPropsItems = {
    treeData: opt,
    value: treeId,
    allowClear: true,   
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: placeholder,
    style: { width: "100%" },
    filterTreeNode: (search, item) => {
      return validateTreeSelectFilter(search, item);
    },
    onDropdownVisibleChange: () => setState(""),
    size: "small",
    showSearch: true,
    treeDefaultExpandAll: false,
    treeNodeFilterProp: "title",
    treeLine: true,
    ...rest
  };

  return (
    <>
      <Form.Item name={name} label={label} noStyle={noStyle}>
        <TreeSelect {...tPropsItems} />
      </Form.Item>
    </>
  );
};

export default SelectTreeMulty;
