import React, { useEffect, useMemo, useState } from "react";
import _t from "../../../languages/translate";
import CardsScreen from "../../helper/cardScreen";
const PageOrders = () => {
  const list = [
    {
      key: "key_absence",
      title: _t("strAbsence"),
      type:1
    },
    {
      key: "key_appointment",
      title: _t("strOneMoreDay"),
      type:2
    },
    {
      key: "key_wait",
      title: _t("strDelay"),
      type:3
    },
    {
      key: "key_upcoming",
      title: _t("strExtraTime"),
      type:4
    },
    {
      key: "key_suitcase",
      title: _t("strRequestMission"),
      type:5
    },
    {
      key: "key_vacation",
      title: _t("strLeaveRequest"),
      type:6
    },
    {
      key: "key_cash",
      title: _t("strAdvanceRequest"),
      type:7
    },
  ];



  return (
    // <div className="homeContent">
      <CardsScreen list={list} />
    // </div>
  );
};

export default PageOrders;
