import React, { useEffect } from "react";
import { logout } from "../../../services/Authorization/authService";

const Logout = () => {
  useEffect(() => {
    logout();
    window.location.replace(`${process.env.PUBLIC_URL}/`);
  }, []);

  return null;
};

export default Logout;
