
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/MunfPatt";

export function searchMunfPatt(search) {
  return http.post(
    `${apiEndpoint}/GetRep/?search=${search}`
  );
}




