export function calclocationCircle(
  accuracy,
  timestamp,
  longitude,
  latitude,
  centerLongitude,
  centerLatitude,
  radius
) {
 
  if (arePointsNear(longitude, latitude, centerLongitude,centerLatitude,radius)) {
    if (checkState(accuracy, timestamp)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

 function checkState(accuracy, timestamp) {
  var nowTs = Date.now();
  var minTs = nowTs - 1000 * 10;
  var maxTs = nowTs + 1000 * 10;
  if (accuracy > 50 || timestamp < minTs || timestamp > maxTs) {
    return false;
  } else {
    return true;
  }
}


function arePointsNear(longitude, latitude, centerLongitude,centerLatitude, m) {
    let km = m / 1000;
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerLatitude / 180.0) * ky;
    var dx = Math.abs(centerLongitude - longitude) * kx;
    var dy = Math.abs(centerLatitude - latitude) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }