
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/PayMethod";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getPayMethodById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}



export function getActiveUnit(search, addId) {
  const params = new URLSearchParams()
  search && params.append("search", search);
  addId && params.append("addId", addId); 

 return `${apiEndpoint}/GetActive/?${params.toString()}`;
}

export function getActivePayMethodSearch(search) {
  return http.post(
    `${apiEndpoint}/GetActiveSearch?search=${search}`
  );
}




export function editPayMethod(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllUnit() {
  return http.get(`${apiEndpoint}`);
}
export function deletePayMethod(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerPayMethod(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewPayMethodCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
