import React, { useState, useEffect, useContext } from "react";
import { saveAs } from "file-saver";
import _t from "../../languages/translate";
import { useLocation } from "react-router-dom";
import { printPanel } from "../helper/Print/printPanel";
import {
  Button,
  message,
  Space,
  Divider,
} from "antd";
import { columnsToObject } from "../helper/helperMethods";

import {
  PrinterOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import FilterLedger from "./filterModal/FilterLedger";
import DataTable, { pageSizeKey } from "../helper/gridDataTable/dataTable";
import {
  getLedgerReport,
  getLedgerHtml,
  getLedgerXls,
  getLedgerPdf,
} from "../../services/Reports/ReportService";
import { handleEx } from "../helper/handleException";
import { LanguageContext } from "../../languages/Language";

const LedgerReport = () => {
  let location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [reportFilter, setReportFilter] = useState({});
  const [columns, setColumns] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [visible, setVisible] = useState(false);
  const [objSearchState, setObjSearchState] = useState(null);
  const { userLanguage } = useContext(LanguageContext);
  const savedPageSize = localStorage.getItem(pageSizeKey);
  const [meta, setMeta] = useState({});

  const handleLinkDataObj = () => {
    let dataObj = JSON.parse(localStorage.getItem("linkObj"));
    setObjSearchState(dataObj);
    localStorage.removeItem("linkObj");
  };

  useEffect(() => {
    if (localStorage.getItem("linkObj") !== null) {
      handleLinkDataObj();
    } else {
      setVisible(true);
    }
  }, [])


  const getView = async () => {
    try {

      const query = new URLSearchParams(location.search);
      if (savedPageSize && !query.has(pageSizeKey))
        query.set(pageSizeKey, savedPageSize);
      if (objSearchState !== null) {

        const { data: responce } = await getLedgerReport(
          query.toString(),
          objSearchState
        );

        if (responce.data.dataSource.length > 0) {

          setColumns(responce.data.columns);
          setDataSource(responce.data.dataSource);
          
          setReportFilter(responce.data);

          setMeta(responce.meta);
        } else {
          setDataSource([]);
          message.info(_t("msgThereIsNoData"), 3);
        }
      }
    } catch (error) {
      handleEx(error);
    }
    finally {
      setLoadData(false);
    }
  }

  useEffect(() => {
    getView()
  }, [location.search, savedPageSize, objSearchState]);



  const showDrawer = () => {
    setVisible(true);
  };

  const handleInStockRep = async (data) => {
    setLoadData(true);
    data.columns = columnsToObject("Rep_Ledger");

    setObjSearchState(data);
    setVisible(false);
  };

  const realodTable = () => {
    setLoadData(true);
    getView()
  }

  const onPrint = async (obj) => {
    try {
      setLoadData(true);
      obj.columns = columnsToObject("Rep_Ledger");
      const data = await getLedgerHtml(objSearchState, userLanguage === "ar" ? 0 : 1);
      setLoadData(false);
      printPanel(data.data)
    } catch (error) {
      handleEx(error);
    }
  };

  const onExportToXls = async (obj) => {
    const hide = message.loading(_t("strDataExporting"), 0);
    try {
      obj.columns = columnsToObject("Rep_Ledger");
      const query = new URLSearchParams(location.search);
      const { data, headers } = await getLedgerXls(
        query.toString(),
        obj,
        userLanguage === "ar" ? 0 : 1
      );
      const byteCharacters = atob(data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, "LedgerReport.xlsx");
    } catch (error) {
      handleEx(error);
    } finally {
      setTimeout(hide);
    }
  };

  const onExportToPDF = async (obj) => {
    const hide = message.loading(_t("strDataExporting"), 0);
    try {
      obj.columns = columnsToObject("Rep_Ledger");
      const query = new URLSearchParams(location.search);
      const { data, headers } = await getLedgerPdf(
        query.toString(),
        obj,
        userLanguage === "ar" ? 0 : 1
      );
      const byteCharacters = atob(data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, "LedgerReport.pdf");
      setTimeout(hide);
    } catch (error) {
      handleEx(error);
    } finally {
      setTimeout(hide);
    }
  };


  const actionButtons = (
    <div className="actionButtons">
      <Space size="small">
        <Button className="addNewButton" type="primary" size="small" onClick={showDrawer} >
          {_t("strSearch")}
        </Button>
        <Divider type="vertical" />
        <Button onClick={() => onPrint(objSearchState)} size="small" type="link"
          style={{ width: 100, borderRadius: 5 }}
          icon={<PrinterOutlined />}
          disabled={dataSource.length !== 0 ? false : true}
        >
          {_t("strPrint")}
        </Button>
        <Divider type="vertical" />
        <Button
          size="small"
          onClick={() => {
            onExportToXls(objSearchState);
          }}
          type="link"
          style={{ width: 100, borderRadius: 5 }}
          disabled={dataSource.length !== 0 ? false : true}
          icon={<FileExcelOutlined style={{ fontSize: 16 }} />}
        >
          {_t("strExportToExcel")}
        </Button>
        <Divider type="vertical" />
        <Button
          size="small"
          onClick={() => {
            onExportToPDF(objSearchState);
          }}
          type="link"
          style={{ width: 100, borderRadius: 5 }}
          disabled={dataSource.length !== 0 ? false : true}
          icon={<FilePdfOutlined style={{ fontSize: 16 }} />}
        >
          {_t("strExportToPDF")}
        </Button>

      </Space>
    </div>
  );



  return (
    <>
      <FilterLedger visible={visible}
        setVisible={setVisible}
        onFilterData={reportFilter !== null ? reportFilter : undefined}
        onHandelData={(data) => handleInStockRep(data)} />

      <DataTable
        style={{ height: "calc(100vh - 195px)", width: "100%" }}
        columns={[...columns]}
        cIndexExp={0}
        dataSource={dataSource}
        tableName="Rep_Ledger"
        tree={true}
        frezzColExp={false}
        actionButtons={actionButtons}
        meta={meta}
        handleReload={realodTable}
        pageName={_t("strLedger")}
        groupName={_t("strReports")}
        type="rep"
        reloadState={objSearchState === null}
        reportFilter={reportFilter.reportFilter}
        loading={loadData}
      />

    </>

  );
};

export default LedgerReport;
