import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  Cascader,
  InputNumber,
  Card,
  Tabs,
  Checkbox,
} from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActiveTreeRepAccount } from "../../../services/AccountService";
import { getActiveTreeRepClient } from "../../../services/clientService";
import { getActivePayMethodSearch } from "../../../services/Items/payMethodService";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import { getAccCatTreeSearch } from "../../../services/AccountCatService";
import { getActiveTreeRepItems } from "../../../services/Items/itemService";
import { getActiveTreeStoreRep } from "../../../services/storeService";
import { getUnitRep } from "../../../services/Items/itemUnitService";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  getCurrActiveSearch,
  getDefaultIdByAccount,
} from "../../../services/Items/currenciesService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import {
  emptValue,
  emptArr,
  emptDate,
  dateRange,
  emptString,
  convertISOString
} from "../../helper/helperMethods";
import { getInvPatt } from "../../../services/InvPatService";
import Draggable from "react-draggable";

const FilterItemsMove = ({
  visible,
  setVisible,
  onHandelData,
  onFilterData,
}) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const draggleRef = useRef(null);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [itemOpt, setItemOpt] = useState([]);
  const [itemState, setItemState] = useState("");
  const [itemTypeSingleIds, setItemTypeSingleIds] = useState([]);
  const [storeOpt, setStoreOpt] = useState([]);
  const [storeState, setStoreState] = useState("");
  const [storeTreeId, setStoreTreeId] = useState([""]);
  const [accountOpt, setAccountOpt] = useState([]);
  const [accountState, setAccountState] = useState("");
  const [accountTreeId, setAccountTreeId] = useState([]);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [accountTypeState, setAccountTypeState] = useState("");
  const [accountTypeTreeId, setAccountTypeTreeId] = useState([]);
  const [clientOpt, setClientOpt] = useState([]);
  const [clientState, setClientState] = useState("");
  const [clientTreeId, setClientTreeId] = useState([]);
  const [repSourceOpt, setRepSourceOpt] = useState([]);
  const [repSourceState, setRepSourceState] = useState("");
  const [repSourceTreeId, setRepSourceTreeId] = useState([""]);
  const [unitOpt, setUnitOpt] = useState([]);
  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      onItemSearch("");
      onStoreSearch("");
      handleGetUnit();
      handlePriceMethod("");
      handlePayMethod("");
      handleSearchCurrency("");
      onAccountsTypeSearch("");
      onAccountsSearch("");
      onClientSearch("");
      onRepSourceSearch("");
      if (onFilterData?.reportFilter !== undefined) {
        form.setFieldsValue({
          itemIds: emptArr(onFilterData?.reportFilter?.itemIds, undefined),
          storeIds: emptArr(onFilterData?.reportFilter?.storeIds, undefined),
          accCatIds: emptArr(onFilterData?.reportFilter?.accCatIds, undefined),
          accIds: emptArr(onFilterData?.reportFilter?.accIds, undefined),
          clientIds: emptArr(onFilterData?.reportFilter?.clientIds, undefined),
          invPattIds: emptArr(
            onFilterData?.reportFilter?.invPattIds,
            undefined
          ),
          priceMethodIds: emptArr(
            onFilterData?.reportFilter?.priceMethodIds,
            undefined
          ),
          payMethodIds: emptArr(
            onFilterData?.reportFilter?.payMethodIds,
            undefined
          ),
          unitType: emptArr(onFilterData?.reportFilter?.unitType, undefined),
          itemType: emptArr(onFilterData?.reportFilter?.itemType, undefined),
          entPostTypes: emptArr(
            onFilterData?.reportFilter?.entPostTypes,
            undefined
          ),
          totalViewTypes: emptArr(
            onFilterData?.reportFilter?.totalViewTypes,
            undefined
          ),
          fromPostDate: emptDate(onFilterData?.reportFilter?.fromPostDate, ""),
          toPostDate: emptDate(onFilterData?.reportFilter?.toPostDate, ""),
          fromDate: emptDate(onFilterData?.reportFilter?.fromDate, ""),
          toDate: emptDate(onFilterData?.reportFilter?.toDate, ""),
          treeLevel: emptValue(onFilterData?.reportFilter?.treeLevel, undefined),
          currId: emptString(onFilterData?.reportFilter?.currId, undefined),
          viewType: emptValue(onFilterData?.reportFilter?.viewType, undefined),
          reportSign: emptValue(onFilterData?.reportFilter?.reportSign, undefined),
          getCost: onFilterData?.reportFilter?.getCost,
        });
      }
    };
    fetchData();
  }, [onFilterData]);

  const GetDefaultIdByAccount = async (ids) => {
    try {
      if (ids.length === 1) {
        const { data: data } = await getDefaultIdByAccount(ids[0]);
        form.setFieldsValue({
          currId: data?.data,
        });
      } else {
        form.setFieldsValue({
          currId: window.localStorage.getItem("currId"),
        });
      }
    } catch (error) {
      handleEx(error);
    }
  };



  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.fromDate = convertISOString(values?.fromDate);
    values.toDate = convertISOString(values?.toDate);
    values.fromPostDate = convertISOString(values?.fromPostDate);
    values.toPostDate = convertISOString(values?.toPostDate);

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const onItemSearch = async (searchValue) => {
    try {
      setItemState(searchValue);
      let obj = {
        search: searchValue,
        addIds: form.getFieldValue("itemIds"),
        types: itemTypeSingleIds,
      };

      const { data: result } = await getActiveTreeRepItems(obj);

      setItemOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onItemChange = (value) => {
    if (value.length === 0) {
      onItemSearch("");
    }
  };

  const onStoreSearch = async (searchValue) => {
    try {
      setStoreState(searchValue);
      let obj = {
        search: searchValue,
        addIds: form.getFieldValue("storeIds"),
      };
      const { data: result } = await getActiveTreeStoreRep(obj);
      setStoreOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onStoreChange = (value) => {
    setStoreTreeId(value);
  };

  const onAccountsSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: accountTreeId,
        clientIds: clientTreeId,
        accCatIds: accountTypeTreeId,
      };

      setAccountState(searchValue);
      const { data: result } = await getActiveTreeRepAccount(obj);
      setAccountOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onAccountsChange = (value) => {
    setAccountTreeId(value);
  };

  const onAccountsTypeSearch = async (searchValue) => {
    try {
      setAccountTypeState(searchValue);

      const { data: result } = await getAccCatTreeSearch(searchValue, "");
      setAccountTypeOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onAccountsTypeChange = (value) => {
    setAccountTypeTreeId(value);
  };

  const onClientSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: clientTreeId,
        accIds: accountTreeId,
      };
      const { data: result } = await getActiveTreeRepClient(obj);
      setClientOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    onAccountsSearch("");
    onClientSearch("");
  }, [clientTreeId, accountTreeId, accountTypeTreeId]);

  useEffect(() => {
    onItemSearch("");
  }, [itemTypeSingleIds]);

  const onClientChange = (value) => {
    setClientTreeId(value);
  };

  const onRepSourceSearch = async (_Search) => {
    try {
      const { data: result } = await getInvPatt(_Search);
      setRepSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handlePayMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePayMethodSearch(_Search);
      setPayMethodOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handlePriceMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleItemTypeChange = (value) => {
    setItemTypeSingleIds(value);
  };

  const handleGetUnit = async () => {
    try {
      const { data: responce } = await getUnitRep("Rep_ItemMove");
      setUnitOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          {_t("strItemMovementReport")}
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          fromDate: dateRange("month"),
          toDate: dateRange(),   
          currId: window.localStorage.getItem("currId"),
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item label={_t("strItems")}>
                    <Input.Group compact>
                      <Form.Item name="itemType" noStyle>
                        <SelectMulty
                          style={{ width: "30%" }}
                          placeholder={_t("strItemType")}
                          onChange={handleItemTypeChange}
                          label={""}
                          opt={[
                            { id: 0, name: _t("strStocked"), filter1: _t("strStocked") },
                            { id: 1, name: _t("strManufactured"), filter1: _t("strManufactured") },
                            { id: 2, name: _t("strComposite"), filter1: _t("strComposite") },
                            { id: 3, name: _t("strUnStocked"), filter1: _t("strUnStocked") },
                          ]}
                        />
                      </Form.Item>
                      <SelectTreeMulty
                        style={{ width: "70%" }}
                        noStyle={true}
                        name="itemIds"
                        label={""}
                        opt={itemOpt}
                        setOpt={setItemOpt}
                        state={itemState}
                        setState={setItemState}
                        placeholder={_t("strItem")}
                        onChange={onItemChange}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="storeIds"
                    label={_t("strStores")}
                    opt={storeOpt}
                    setOpt={setStoreOpt}
                    state={storeState}
                    setState={setStoreState}
                    treeId={storeTreeId}
                    onChange={onStoreChange}
                  />
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="unitType"
                    tooltip={{
                      title: _t("msgItemUnitRepType"),
                      icon: <QuestionCircleOutlined />,
                    }}
                    label={_t("strUnit")}
                  >
                    <Cascader
                      showSearch
                      allowClear={true}
                      // style={{width:"150px"}}
                      placeholder={_t("strNotSelected")}
                      optionFilterProp="children"
                      options={unitOpt}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      lable={_t("strDate")}
                      fromName="fromDate"
                      toName="toDate"
                      defaultRange={4}
                    />
                  </Row>
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item label={_t("strAccount")}>
                    <Input.Group compact>
                      <SelectTreeMulty
                        name="accCatIds"
                        label=""
                        noStyle
                        style={{ width: "30%" }}
                        placeholder={_t("strAccountType")}
                        opt={accountTypeOpt}
                        setOpt={setAccountTypeOpt}
                        state={accountTypeState}
                        setState={setAccountTypeState}
                        treeId={accountTypeTreeId}
                        onChange={onAccountsTypeChange}
                      />

                      <SelectTreeMulty
                        name="accIds"
                        label=""
                        noStyle
                        style={{ width: "70%" }}
                        placeholder={_t("strAccount")}
                        opt={accountOpt}
                        setOpt={setAccountOpt}
                        state={accountState}
                        setState={setAccountState}
                        treeId={accountTreeId}
                        onChange={(e) => {
                          onAccountsChange(e);
                          GetDefaultIdByAccount(e);
                        }}

                      />
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="clientIds"
                    label={_t("strClients")}
                    opt={clientOpt}
                    setOpt={setClientOpt}
                    state={clientState}
                    setState={setClientState}
                    treeId={clientTreeId}
                    onChange={onClientChange}

                  />
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="المتقدم" key="2" forceRender>
            <Row gutter={12}>
              <Col span={24}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col span={24}>
                    <SelectTreeMulty
                      name="InvPattIds"
                      label={_t("strInvoices")}
                      opt={repSourceOpt}
                      setOpt={setRepSourceOpt}
                      state={repSourceState}
                      setState={setRepSourceState}
                      treeId={repSourceTreeId}
                      onChange={setRepSourceTreeId}

                    />
                  </Col>
                </Card>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item
                          name="payMethodId"
                          label={_t("strPaymentMethod")}
                        >
                          <SelectMulty
                            opt={payMethodOpt}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="priceMethodId"
                          label={_t("strPriceMethod")}
                        >
                          <SelectMulty
                            opt={priceMethodOpt}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24}>
                    <Row>
                      <TimeRange
                        mainForm={Form}
                        form={form}
                        lable={_t("strPostingDate")}
                        fromName="fromPostDate"
                        toName="toPostDate"                  
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="totalViewTypes" label={_t("strTotal")}>
                      <SelectMulty
                        style={{ width: "100%" }}
                        opt={[
                          { id: 0, name: _t("strTermBalance"), filter1: _t("strTermBalance") },
                          { id: 1, name: _t("strPreviousBalance"), filter1: _t("strPreviousBalance") },
                          { id: 2, name: _t("strFinalBalance"), filter1: _t("strFinalBalance") },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="الخيارات" key="3" forceRender>
            <Row gutter={12}>
              <Col span={16}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item name="currId" label={_t("strCurrency")}  >
                          <SelectSingle
                          allowClear={false}
                            fieldNames={{
                              label: "currName",
                              value: "currId",
                            }}
                            opt={currencyOpt}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="viewType" label={_t("strShowStyle")}  >
                          <SelectSingle
                            opt={[{ id: 0, name: _t("strDetailed"), filter1: _t("strDetailed") },
                            { id: 1, name: _t("strShort"), filter1: _t("strShort") }]}
                          />
                        </Form.Item>

                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="reportSign"
                          label={_t("strReportSignal")}
                        >
                          <SelectSingle
                            opt={[{ id: 0, name: _t("strPositiveInPositiveOut"), filter1: _t("strPositiveInPositiveOut") },
                            { id: 1, name: _t("strPositiveInNegativeOut"), filter1: _t("strPositiveInNegativeOut") }]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="entPostTypes" label={_t("strEntryStatus")}>
                          <SelectMulty
                            opt={[
                              { id: 0, name: _t("strPosted"), filter1: _t("strPosted") },
                              { id: 1, name: _t("strUnPosted"), filter1: _t("strUnPosted") }
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="treeLevel" label={_t("strLevel")}>
                          <InputNumber
                            size="small"
                            placeholder={_t("strLevel")}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{
                    width: "100%",
                    height: "95%",
                    margin: "5px 0px 5px 0px",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Form.Item
                    name="getCost"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>{_t("strGetCost")}</Checkbox>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FilterItemsMove;
