import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Row, Col, Empty, Card, Tabs } from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActiveJournal } from "../../../services/journalService";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import { getInvPatt } from "../../../services/InvPatService";
import { getEntPatt } from "../../../services/EntPatService";
import {
  emptDate,
  emptArr,
  emptString,
  dateRange,
  convertISOString,
} from "../../helper/helperMethods";
import Draggable from "react-draggable";

const FilterJournal = ({ visible, setVisible, onHandelData, onFilterData }) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const draggleRef = useRef(null);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [jornalOpt, setJornalOpt] = useState([]);
  const [invPattSourceOpt, setInvPattSourceOpt] = useState([]);
  const [invPattSourceState, setInvPattSourceState] = useState("");
  const [invPattSourceTreeId, setInvPattSourceTreeId] = useState([""]);
  const [entPattSourceOpt, setEntPattSourceOpt] = useState([]);
  const [entPattSourceState, setEntPattSourceState] = useState("");
  const [entPattSourceTreeId, setEntPattSourceTreeId] = useState([""]);

  useEffect(() => {
    const fetchData = async () => {
      handleSearchCurrency("");
      onEntPattSourceSearch("");
      onInvPattSourceSearch("");
      handleJornal("");
      if (onFilterData?.reportFilter !== undefined) {
        form.setFieldsValue({
          journalIds: emptArr(
            onFilterData?.reportFilter?.journalIds,
            undefined
          ),
          fromDate: emptDate(onFilterData?.reportFilter?.fromDate, ""),
          toDate: emptDate(onFilterData?.reportFilter?.toDate, ""),
          entPattIds: emptArr(
            onFilterData?.reportFilter?.entPattIds,
            undefined
          ),
          invPattIds: emptArr(
            onFilterData?.reportFilter?.invPattIds,
            undefined
          ),
          fromPostDat: emptDate(onFilterData?.reportFilter?.fromPostDat, ""),
          toPostDate: emptDate(onFilterData?.reportFilter?.toPostDate, ""),
          entPostTypes: emptArr(
            onFilterData?.reportFilter?.entPostTypes,
            undefined
          ),
          currId: emptString(onFilterData?.reportFilter?.currId, undefined),
          viewType: emptString(onFilterData?.reportFilter?.viewType, undefined),
        });
      }
    };
    fetchData();
  }, [onFilterData]);

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.fromDate = convertISOString(values?.fromDate);
    values.toDate = convertISOString(values?.toDate);
    values.fromPostDate = convertISOString(values?.fromPostDate);
    values.toPostDate = convertISOString(values?.toPostDate);
    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const handleJornal = async (_Search) => {
    try {
      const { data: responce } = await getActiveJournal(_Search, "");
      setJornalOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onEntPattSourceSearch = async (_Search) => {
    try {
      setEntPattSourceState(_Search);
      const { data: result } = await getEntPatt(_Search);
      setEntPattSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onEntPattSourceChange = (value) => {
    setEntPattSourceTreeId(value);
  };

  const onInvPattSourceSearch = async (_Search) => {
    try {
      setInvPattSourceState(_Search);
      const { data: result } = await getInvPatt(_Search);
      setInvPattSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onInvPattSourceChange = (value) => {
    setInvPattSourceTreeId(value);
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strJournal")}
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          fromDate: dateRange("month"),
          toDate: dateRange(),
          currId: window.localStorage.getItem("currId"),
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item name="journalIds" label={_t("strJournal")}>
                    <SelectMulty
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      placeholder={_t("strJournal")}
                      size="small"
                      opt={jornalOpt}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <TimeRange
                    mainForm={Form}
                    form={form}
                    lable={_t("strDate")}
                    fromName="fromDate"
                    toName="toDate"
                    defaultRange={4}
                  />
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="المتقدم" key="2" forceRender>
            <Row>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <SelectTreeMulty
                    name="entPattIds"
                    label={_t("strEntries")}
                    opt={entPattSourceOpt}
                    setOpt={setEntPattSourceOpt}
                    state={entPattSourceState}
                    setState={setEntPattSourceState}
                    treeId={entPattSourceTreeId}
                    setTreeId={setEntPattSourceTreeId}
                    onChange={onEntPattSourceChange}                 
                  />
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="invPattIds"
                    label={_t("strInvoices")}
                    opt={invPattSourceOpt}
                    setOpt={setInvPattSourceOpt}
                    state={invPattSourceState}
                    setState={setInvPattSourceState}
                    treeId={invPattSourceTreeId}
                    setTreeId={setInvPattSourceTreeId}
                    onChange={onInvPattSourceChange}                
                  />
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <TimeRange
                    mainForm={Form}
                    form={form}
                    lable={_t("strPostingDate")}
                    fromName="fromPostDate"
                    toName="toPostDate"
                  />
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="الخيارات" key="3" forceRender>
            <Row gutter={12}>
              <Col span={24}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item
                          label={_t("strEntryStatus")}
                          name="entPostTypes"
                        >
                          <SelectMulty
                            placeholder={_t("strEntryStatus")}
                            opt={[
                              {
                                id: 0,
                                name: _t("strPosted"),
                                filter1: _t("strPosted"),
                              },
                              {
                                id: 1,
                                name: _t("strUnPosted"),
                                filter1: _t("strUnPosted"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item label={_t("strCurrency")} name="currId">
                          <SelectSingle
                          allowClear={false}
                            placeholder={_t("strNotSelected")}
                            fieldNames={{
                              label: "currName",
                              value: "currId",
                            }}
                            opt={currencyOpt}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="viewType" label={_t("strShowStyle")}>
                          <SelectSingle
                            placeholder={_t("strNotSelected")}
                            opt={[
                              {
                                id: 0,
                                name: _t("strDetailed"),
                                filter1: _t("strDetailed"),
                              },
                              {
                                id: 1,
                                name: _t("strTotal2"),
                                filter1: _t("strTotal2"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FilterJournal;
