export const toggleSubRow = (rows, status, id) => {
  const newRows = [...rows];
  
  if (status === "expand") {
    for (let i = 0; i < newRows.length; i++) {
      // if (newRows[i].parentId === null) {
        if (!newRows[i].isExpanded) {
          i = getRowsForExpand(newRows, i, true);
          
        }
     // }
    }
    
  } else if (status === "unExpand") { 
    for (let i = 0; i < newRows.length; i++) {
      // if (newRows[i].parentId === null) { 
        if (newRows[i].isExpanded) {
          getRowsForDelete(newRows, i);
        }
      //}
    }
  } else if (status === "toggleSubRow") {
    const rowIndex = rows.findIndex((r) => r.id === id);
    const row = rows[rowIndex];
    const { children } = row;
    if (!children) return rows;
    if (!row.isExpanded) {
      getRowsForExpand(newRows, rowIndex, false);
    } else {
      getRowsForDelete(newRows, rowIndex);
    }
  }
  
  return newRows;

};

const getRowsForExpand = (arr, rowIndex, isAll) => {
  if (!arr[rowIndex].isExpanded) {   
    arr[rowIndex] = { ...arr[rowIndex], isExpanded:true
    };
    arr[rowIndex].childArr={...arr[rowIndex].children}
    arr.splice(rowIndex + 1, 0, ...arr[rowIndex].children);
    let leng = rowIndex;
    if (isAll) {
      
      leng = renderExpandRow(arr, rowIndex);
      return leng;
    }
  }
};

const renderExpandRow = (arr, rowIndex) => {
  let leng = rowIndex;
  for (let i = 0; i < arr[rowIndex].children.length; i++) {
    leng++;

arr[rowIndex]={...arr[rowIndex], isExpanded:true};
    arr.splice(leng + 1, 0, ...arr[rowIndex].children[i].children);
  leng=  renderExpandRow(arr, leng);
  }
  return leng;
};

const getRowsForDelete = (arr, rowIndex) => {
  let leng = 0;
  if (arr[rowIndex].isExpanded) {
    arr[rowIndex] = { ...arr[rowIndex], isExpanded:false// !arr[rowIndex].isExpanded 
    };
    leng = renderDeleteRow(arr, arr[rowIndex].id);
  }
  arr.splice(rowIndex + 1, leng);

  return leng;
};




const renderDeleteRow = (arr, parnetId) => {
  let childArr = arr.filter((x) => x.parentId === parnetId);
  let leng = 0;
  for (let i = 0; i < childArr.length; i++) {
    leng++;
    if (childArr[i].isExpanded) {
      childArr[i] = { ...childArr[i], isExpanded:false
       };
      leng += renderDeleteRow(arr, childArr[i].id);
    }
  }
 
  return leng;
};
