
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/UserRole";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getInitiale() {
  return http.post(`${apiEndpoint}/Initiale`);
}

export function getGeneralRolesById(Id) {
 
  return http.get(`${apiEndpoint}/${Id}`);
}



export function getRoleTableDetail(userRoleId, roleTypeID) {

  return http.post(`${apiEndpoint}/GetDetail?userRoleId=${userRoleId}&roleTypeID=${roleTypeID}`);
}


export function editGeneralRoles(obj) {
  

  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    delete obj.id
    obj.inactive = false;
    return http.post(apiEndpoint, obj);
  }
}


export function getAllGeneralRoles() {
  return http.get(`${apiEndpoint}`);
}
export function deleteGeneralRoles(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerGeneralRoles(current, pageSize) {
  return http.post(`${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`);
}

export function getNewItemUnitCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
