import {
  HomeOutlined,
  FileSearchOutlined,
  ContainerOutlined,
  SettingOutlined
} from "@ant-design/icons";
import _t from "../../../../languages/translate";
import { Breadcrumb, Typography } from "antd";

const pathText = ({ pageName, branchName= "Unknow", groupName, type }) => {
  const { Text } = Typography;
  return (
    <div className="breadcrumbStyle">
      <Breadcrumb>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        {type === "rep" && (
          <Breadcrumb.Item>
            <FileSearchOutlined />
            <span>{groupName}</span>
          </Breadcrumb.Item>
        )}
        {type === "page" && (
          <Breadcrumb.Item>
            <ContainerOutlined />
            <span>{groupName}</span>
          </Breadcrumb.Item>
        )}
        {type === "sett" && (
          <Breadcrumb.Item>
            <SettingOutlined />
            <span>{groupName}</span>
          </Breadcrumb.Item>
        )}
        {branchName !== "Unknow" && (
          <Breadcrumb.Item>
            <span>{branchName}</span>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>
          <Text style={{fontWeight:700}}>{pageName}</Text>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default pathText;
