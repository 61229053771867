import React, { useState, useEffect, useRef } from "react";
import { Form, Modal } from "antd";
import _t from "../../../languages/translate";
import Draggable from "react-draggable";
import SelectMulty from "../../helper/selectMulty";

const FilterManageOrders = ({ showModal, setShowModal, onHandleData }) => {
  const [form] = Form.useForm();
  const draggleRef = useRef(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (showModal) {
     //   form.setFieldsValue({});
      }
    };
    fetchData();
  }, [showModal]);

  const onFinish = async (values) => {
    
    onHandleData(values);
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strOrders")}
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={showModal}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
      onCancel={() => setShowModal(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
      <Form
        form={form}
        name="search"
        layout="vertical"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{ statuses: [0], reqTypes: [] }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="reqTypes" label={_t("strReqType")}>
          <SelectMulty
            opt={[
              { name: _t("strAbsence"), id: 1 },
              { name: _t("strOneMoreDay"), id: 2 },
              { name: _t("strDelay"), id: 3 },
              { name: _t("strExtraTime"), id: 4 },
              { name: _t("strRequestMission"), id: 5 },
              { name: _t("strLeaveRequest"), id: 6 },
              { name: _t("strAdvanceRequest"), id: 7 },
            ]}
            size="large"
          />
        </Form.Item>
        <Form.Item name="statuses" label={_t("strStatuses")}>
          <SelectMulty
            opt={[
              { name: _t("strWaiting"), id: 0 },
              { name: _t("strRejected"), id: 1 },
              { name: _t("strAccepted"), id: 2 },
              { name: _t("strApplied"), id: 3 },
            ]}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FilterManageOrders;
