import React, { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import { getCurrSalary } from "../../../services/EmpSalaryService";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { emptString } from "../../helper/helperMethods";

const SalaryPage = () => {
  const [loadData, setLoadData] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    async function feachData() {
      await setLoadData(true);
      await getSalaryList();
      await setLoadData(false);
    }
    feachData();
  }, []);

  const col = [
    {
      dataIndex: "Name",
      key: "Name",
    },
    {
      dataIndex: "Value",
      key: "Value",
    },
    {
      dataIndex: "Description",
      key: "Description",
    },
  ];

  const _col = [
    {
      dataIndex: "Name",
      key: "Name",
    },
    {
      dataIndex: "Value",
      key: "Value",
    },
  ];

  const drawingCloumns = (array) => {
    let drawingDisc = false;
    for (let i = 0; i < array.length; i++) {
      if (array[i].Description !== null) {
        drawingDisc = true;
      }
    }
    setColumns(drawingDisc ? col : _col);
  };

  const getSalaryList = async () => {
    try {
      const { data: data } = await getCurrSalary();
      if (emptString(data.data, null) !== null) {
        const _data = JSON.parse(data?.data);
        drawingCloumns(_data);
        setDataSource(_data);
      }
    } catch (error) {
      handleEx(error);
    }
  };

  return (
    <Spin spinning={loadData} tip={_t("strLoading")}>
      <div className="frContent">
        <Table
          size="small"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </Spin>
  );
};

export default SalaryPage;
