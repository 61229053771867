import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/AttLog";

export function getDetailsById(id) {
  return http.post(`${apiEndpoint}/GetDetailsById/?id=${id}&api-v=1.0`);
}



export function saveAttLog(obj) {
  return http.post(`${apiEndpoint}/?api-v=1.0`, obj);
}
