import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _t from "../../../languages/translate";
import {
  Button,
  Card,
  Divider,
  List,
  message,
  Modal,
  Space,
  Spin,
  Typography,
  Row,
  Col,
} from "antd";
import { handleEx } from "../../helper/handleException";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  InboxOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { _content, _extra, _title, _tag, _data, _pageSizeKey } from "./healper";
import {
  getPagerReqApp,
  deleteReqApp,
  getReqAppById,
  getDetailsById,
} from "../../../services/ReqAppService";
import SendOrderModal from "../../forms/sendOrder/fr_SendOrderForm";
import PrivOrder from "../../forms/previwOrder/privOrder";
import FilterOrders from "./FilterOrders";

const PageOrdersLog = () => {
  const savedPageSize = localStorage.getItem(_pageSizeKey);
  const [loading, setLoading] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showPrivewModal, setShowPrivewModal] = useState(false);
  const [orderObj, setOrderObj] = useState(undefined);
  const [privewObj, strPrivewObj] = useState(undefined);
  const [viewObj, setViewObj] = useState({
    reqTypes: [],
    statuses: [0],
  });
  const { Title } = Typography;
  const [orderType, setOrderType] = useState(1);

  const [meta, setMeta] = useState({});

  let location = useLocation();
  const { confirm } = Modal;

  useEffect(() => {
    getView(viewObj);
  }, [location.search, savedPageSize]);

  const getView = async (obj) => {
    try {
      await setLoading(true);
      const query = new URLSearchParams(location.search);
      if (savedPageSize && !query.has(_pageSizeKey))
        query.set(_pageSizeKey, savedPageSize);
      const { data: responce } = await getPagerReqApp(query.toString(), obj);
      if (responce.data.length > 0) {
        setDataSource(responce?.data);
        setMeta(responce?.meta);
      } else {
        setDataSource([]);
        message.info(_t("msgThereIsNoData"), 3);
      }
      await setLoading(false);
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const removeById = (array, id) => {
    const filteredArray = array.filter((object) => object.id !== id);
    return filteredArray;
  };

  const handleDelete = async (id) => {
    try {
      await setLoading(true);
      const { data: data } = await deleteReqApp(id);
      if (data.code === 200) {
        const newlist = removeById(dataSource, id);
        setDataSource(newlist);
        message.success(_t("strDoneSuccessfully"));
        await setLoading(false);
      }
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      okButtonProps: { size: "large" },
      cancelButtonProps: { size: "large" },
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const getById = async (id) => {
    try {
      await setLoading(true);
      const { data: data } = await getReqAppById(id);

      if (data?.data !== undefined) {
        setTimeout(async () => {
          await setOrderObj(data.data);
          await setOrderType(data.data?.reqAppType);
          await setShowModal(true);
          await setLoading(false);
        }, 500);
      }
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const getPrivewById = async (id) => {
    try {
      await setLoading(true);

      const { data: data } = await getDetailsById(id);

      if (data?.data !== undefined) {
        setTimeout(async () => {
          await strPrivewObj(data.data);
          await setShowPrivewModal(true);
          await setLoading(false);
        }, 500);
      }
    } catch (error) {
      handleEx(error);
      await setLoading(false);
    }
  };

  const replaceObjById = (array, data) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === data.id) {
        array[i] = { ...data };
      }
    }
    return array;
  };

  const onHandleSaveData = (data) => {
    const newArr = replaceObjById(dataSource, data.data);
    setDataSource(newArr);
  };

  const onHandleFilterData = (obj) => {
    setViewObj(obj);
    getView(obj);
    setShowFilterModal(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <div className="contentList">
        <PrivOrder
          showModal={showPrivewModal}
          setShowModal={setShowPrivewModal}
          privewObj={privewObj}
        />
        <FilterOrders
          showModal={showFilterModal}
          setShowModal={setShowFilterModal}
          onHandleData={onHandleFilterData}
        />
        <SendOrderModal
          showModal={showModal}
          setShowModal={setShowModal}
          orderType={orderType}
          orderObj={orderObj}
          onHandleData={onHandleSaveData}
        />
        <Row>
          <Col style={{ textAlign: "start" }} span={16}>
            <Space split={<Divider type="vertical" />}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setShowFilterModal(true);
                }}
                icon={<FilterOutlined />}
              >
                {_t("strSearch")}
              </Button>
              <Title level={5}>
                <InboxOutlined style={{ fontSize: 16 }} /> {_t("strOrdersLog")}
              </Title>
            </Space>
          </Col>

          <Col style={{ textAlign: "end" }} span={8}>
            <Button
              type="link"
              size="large"
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => {
                getView(viewObj);
              }}
              icon={
                <ReloadOutlined style={{ fontSize: 20, color: "#707070" }} />
              }
            />
          </Col>
        </Row>

        <Divider style={{ margin: 5 }} />
        {/* <div className="contentScrolling"> */}
        <div
          style={{ overflowY: "scroll", overflowX: "hidden", height: "86vh" }}
        >
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={dataSource}
            renderItem={(e) => (
              <List.Item>
                <Card
                  title={_title(e)}
                  extra={_extra(e)}
                  headStyle={{ padding: "0 12px" }}
                  actions={[
                    <EyeOutlined
                      key="preview"
                      onClick={() => getPrivewById(e.id)}
                    />,
                    <EditOutlined key="edit" onClick={() => getById(e.id)} />,
                    <DeleteOutlined onClick={() => showDeleteConfirm(e.id)} />,
                  ]}
                >
                  {_content(e)}
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Spin>
  );
};

export default PageOrdersLog;
