import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  InputNumber,
  Card,
  Tabs,
  Checkbox,
} from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import {
  emptArr,
  emptDate,
  dateRange,
  emptString,
  emptValue,
  convertISOString,
} from "../../helper/helperMethods";
import { getActiveTreeRepAccount } from "../../../services/AccountService";
import { getActiveTreeRepClient } from "../../../services/clientService";
import { getAccCatTreeSearch } from "../../../services/AccountCatService";
import {
  getCurrActiveSearch,
  getDefaultIdByAccount,
} from "../../../services/Items/currenciesService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import { getInvPatt } from "../../../services/InvPatService";
import { getActiveTreeRepCostCenters } from "../../../services/CostCenterService";
import { getEntPatt } from "../../../services/EntPatService";
import Draggable from "react-draggable";

const FilterLedger = ({ visible, setVisible, onHandelData, onFilterData }) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const draggleRef = useRef(null);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [accountOpt, setAccountOpt] = useState([]);
  const [accountState, setAccountState] = useState("");
  const [accountTreeId, setAccountTreeId] = useState([]);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [accountTypeState, setAccountTypeState] = useState("");
  const [accountTypeTreeId, setAccountTypeTreeId] = useState([]);
  const [nextAccountOpt, setNextAccountOpt] = useState([]);
  const [nextAccountState, setNextAccountState] = useState("");
  const [nextAccountTreeId, setNextAccountTreeId] = useState([]);
  const [nextAccountTypeOpt, setNextAccountTypeOpt] = useState([]);
  const [nextAccountTypeState, setNextAccountTypeState] = useState("");
  const [nextAccountTypeTreeId, setNextAccountTypeTreeId] = useState([]);
  const [clientOpt, setClientOpt] = useState([]);
  const [clientState, setClientState] = useState("");
  const [clientTreeId, setClientTreeId] = useState([]);
  const [invPattSourceOpt, setInvPattSourceOpt] = useState([]);
  const [invPattSourceState, setInvPattSourceState] = useState("");
  const [invPattSourceTreeId, setInvPattSourceTreeId] = useState([""]);
  const [entPattSourceOpt, setEntPattSourceOpt] = useState([]);
  const [entPattSourceState, setEntPattSourceState] = useState("");
  const [entPattSourceTreeId, setEntPattSourceTreeId] = useState([""]);
 


  useEffect(() => {
    const fetchData = async () => {
      handleSearchCurrency("");
      onAccountsTypeSearch("");
      onNextAccountsTypeSearch("");
      onAccountsSearch("");
      onNextAccountsSearch("");
      onClientSearch("");
      onInvPattSourceSearch("");
      onCostCenterSourceSearch("");
      onEntPattSourceSearch("");
      if (onFilterData?.reportFilter !== undefined) {
        
        form.setFieldsValue({
          accCatIds: emptArr(onFilterData?.reportFilter?.accCatIds, undefined),
          accIds: emptArr(onFilterData?.reportFilter?.accIds, undefined),
          CostCentersIds: emptArr(onFilterData?.reportFilter?.costCentersIds, undefined),
          currId: emptString(onFilterData?.reportFilter?.currId, undefined),
          entPostTypes: emptArr(
            onFilterData?.reportFilter?.entPostTypes,
            undefined
          ),
         
          fromDate: emptDate(onFilterData?.reportFilter?.fromDate, ""),
          fromPostDate: emptDate(onFilterData?.reportFilter?.fromPostDate, ""),
          nextAccIds: emptArr(
            onFilterData?.reportFilter?.nextAccIds,
            undefined
          ),
          showAccType: emptArr(
            onFilterData?.reportFilter?.showAccType,
            undefined
          ),
          toDate: emptDate(onFilterData?.reportFilter?.toDate, ""),
          toPostDate: emptDate(onFilterData?.reportFilter?.toPostDate, ""),
          totalViewTypes: emptArr(
            onFilterData?.reportFilter?.totalViewTypes,
            undefined
          ),
          treeLevel: emptValue(onFilterData?.reportFilter?.treeLevel, undefined),
          opEntIsPrevBalance: onFilterData?.reportFilter?.opEntIsPrevBalance,
        });
      }
    };
    fetchData();
  }, [onFilterData]);

  const GetDefaultIdByAccount = async (ids) => {
    try {
      if (ids.length === 1) {
        const { data: data } = await getDefaultIdByAccount(ids[0]);
        form.setFieldsValue({
          currId: data?.data,
        });
      } else {
        form.setFieldsValue({
          currId: window.localStorage.getItem("currId"),
        });
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const onInvPattSourceSearch = async (_Search) => {
    try {
      setInvPattSourceState(_Search);
      const { data: result } = await getInvPatt(_Search);
      setInvPattSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [costCentersSourceOpt, setCostCentersSourceOpt] = useState([]);
  const [costCentersSourceState, setCostCentersSourceState] = useState("");
  const [costCentersSourceTreeId, setCostCentersSourceTreeId] = useState([""]);

  const onCostCenterSourceSearch = async (_Search) => {
    try {
      setCostCentersSourceState(_Search);
      const { data: result } = await getActiveTreeRepCostCenters({search: _Search});
      setCostCentersSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onCostCentersSourceChange = (value) => {
    setCostCentersSourceTreeId(value);
  };


  const onEntPattSourceSearch = async (_Search) => {
    try {
      setEntPattSourceState(_Search);
      const { data: result } = await getEntPatt(_Search);
      setEntPattSourceOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.fromDate = convertISOString(values?.fromDate);
    values.toDate = convertISOString(values?.toDate);
    values.fromPostDate = convertISOString(values?.fromPostDate);
    values.toPostDate = convertISOString(values?.toPostDate);

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const onAccountsSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: accountTreeId,
        clientIds: clientTreeId,
        accCatIds: accountTypeTreeId,
      };
      setAccountState(searchValue);

      const { data: result } = await getActiveTreeRepAccount(obj);
      setAccountOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onAccountsChange = (value) => {
    setAccountTreeId(value);
  };

  const onNextAccountsSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: nextAccountTreeId,
        clientIds: clientTreeId,
        accCatIds: nextAccountTypeTreeId,
      };

      setNextAccountState(searchValue);
      const { data: result } = await getActiveTreeRepAccount(obj);
      setNextAccountOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onNextAccountsChange = (value) => {
    setNextAccountTreeId(value);
  };

  const onAccountsTypeSearch = async (searchValue) => {
    try {
      setAccountTypeState(searchValue);
      const { data: result } = await getAccCatTreeSearch(searchValue, "");
      setAccountTypeOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onNextAccountsTypeSearch = async (searchValue) => {
    try {
      setNextAccountTypeState(searchValue);
      const { data: result } = await getAccCatTreeSearch(searchValue, "");
      setNextAccountTypeOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onAccountsTypeChange = (value) => {
    setAccountTypeTreeId(value);
  };

  const onNextAccountsTypeChange = (value) => {
    setNextAccountTypeTreeId(value);
  };

  const onClientSearch = async (searchValue) => {
    try {
      let obj = {
        search: searchValue,
        addIds: clientTreeId,
        accIds: accountTreeId,
      };
      setClientState(searchValue);
      const { data: result } = await getActiveTreeRepClient(obj);
      setClientOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    onAccountsSearch("");
    onClientSearch("");
  }, [clientTreeId, accountTreeId, accountTypeTreeId]);

  const onClientChange = (value) => {
    setClientTreeId(value);
  };

  const onEntPattSourceChange = (value) => {
    setEntPattSourceTreeId(value);
  };

  const onInvPattSourceChange = (value) => {
    setInvPattSourceTreeId(value);
  };



  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strLedger")}
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "105px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          fromDate: dateRange("month"),
          toDate: dateRange(),
          currId: window.localStorage.getItem("currId"),
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item label={_t("strAccount")}>
                    <Input.Group compact>
                      <SelectTreeMulty
                        name="accCatIds"
                        label=""
                        noStyle
                        style={{ width: "30%" }}
                        placeholder={_t("strAccountType")}
                        opt={accountTypeOpt}
                        setOpt={setAccountTypeOpt}
                        state={accountTypeState}
                        setState={setAccountTypeState}
                        treeId={accountTypeTreeId}
                        setTreeId={setAccountTypeTreeId}
                        onChange={onAccountsTypeChange}
                      />

                      <SelectTreeMulty
                        name="accIds"
                        label=""
                        noStyle
                        style={{ width: "70%" }}
                        placeholder={_t("strAccount")}
                        opt={accountOpt}
                        state={accountState}
                        setState={setAccountState}
                        treeId={accountTreeId}
                        setTreeId={setAccountTreeId}
                        onChange={(e) => {
                          onAccountsChange(e);
                          GetDefaultIdByAccount(e);
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <SelectTreeMulty
                    name="clientIds"
                    label={_t("strClients")}
                    opt={clientOpt}
                    setOpt={setClientOpt}
                    state={clientState}
                    setState={setClientState}
                    treeId={clientTreeId}
                    setTreeId={setClientTreeId}
                    onChange={onClientChange}
                  />
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      lable={_t("strDate")}
                      fromName="fromDate"
                      toName="toDate"
                      defaultRange={4}
                    />
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="treeLevel" label={_t("strLevel")}>
                    <InputNumber
                      size="small"
                      placeholder={_t("strLevel")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="المتقدم" key="2" forceRender>
            <Row>
              <Col span={24}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col span={24}>
                    <Form.Item label={_t("strNextAcc")}>
                      <Input.Group compact>
                        <SelectTreeMulty
                          name="nextAccCatIds"
                          label=""
                          noStyle
                          style={{ width: "30%" }}
                          placeholder={_t("strAccountType")}
                          opt={nextAccountTypeOpt}
                          setOpt={setNextAccountTypeOpt}
                          state={nextAccountTypeState}
                          setState={setNextAccountTypeState}
                          treeId={nextAccountTypeTreeId}
                          setTreeId={setNextAccountTypeTreeId}
                          onChange={onNextAccountsTypeChange}
                        />

                        <SelectTreeMulty
                          name="nextAccIds"
                          label=""
                          noStyle
                          style={{ width: "70%" }}
                          placeholder={_t("strNextAcc")}
                          opt={nextAccountOpt}
                          setOpt={setNextAccountOpt}
                          state={nextAccountState}
                          setState={setNextAccountState}
                          treeId={nextAccountTreeId}
                          setTreeId={setNextAccountTreeId}
                          onChange={onNextAccountsChange}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Card>
              </Col>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <TimeRange
                    mainForm={Form}
                    form={form}
                    lable={_t("strPostingDate")}
                    fromName="fromPostDate"
                    toName="toPostDate"             
                  />
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="entPattIds"
                    label={_t("strEntries")}
                    opt={entPattSourceOpt}
                    setOpt={setEntPattSourceOpt}
                    state={entPattSourceState}
                    setState={setEntPattSourceState}
                    treeId={entPattSourceTreeId}
                    setTreeId={setEntPattSourceTreeId}
                    onChange={onEntPattSourceChange}
                  />
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="invPattIds"
                    label={_t("strInvoices")}
                    opt={invPattSourceOpt}
                    setOpt={setInvPattSourceOpt}
                    state={invPattSourceState}
                    setState={setInvPattSourceState}
                    treeId={invPattSourceTreeId}
                    setTreeId={setInvPattSourceTreeId}
                    onChange={onInvPattSourceChange}
                  />
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="costCentersIds"
                    label={_t("strCostCenter")}
                    opt={costCentersSourceOpt}
                    setOpt={setCostCentersSourceOpt}
                    state={costCentersSourceState}
                    setState={setCostCentersSourceState}
                    treeId={costCentersSourceTreeId}
                    setTreeId={setCostCentersSourceTreeId}
                    onChange={onCostCentersSourceChange}
                  />
                </Col>
              </Card>
            </Row>
          </TabPane>
          <TabPane tab="الخيارات" key="3" forceRender>
            <Row gutter={12}>
              <Col span={16}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item name="totalViewTypes" label={_t("strTotal")}>
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strTermBalance"),
                                filter1: _t("strTermBalance"),
                              },
                              {
                                id: 1,
                                name: _t("strPreviousBalance"),
                                filter1: _t("strPreviousBalance"),
                              },
                              {
                                id: 2,
                                name: _t("strFinalBalance"),
                                filter1: _t("strFinalBalance"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="entPostTypes"
                          label={_t("strEntryStatus")}
                        >
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strPosted"),
                                filter1: _t("strPosted"),
                              },
                              {
                                id: 1,
                                name: _t("strUnPosted"),
                                filter1: _t("strUnPosted"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="showAccType" label={_t("strShowAcc")}>
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strBlank"),
                                filter1: _t("strBlank"),
                              },
                              {
                                id: 1,
                                name: _t("strBalanced"),
                                filter1: _t("strBalanced"),
                              },
                              {
                                id: 2,
                                name: _t("strNonBalanced"),
                                filter1: _t("strNonBalanced"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="showClientAccType"
                          label={_t("strShowClients")}
                        >
                          <SelectMulty
                            opt={[
                              {
                                id: 0,
                                name: _t("strBlank"),
                                filter1: _t("strBlank"),
                              },
                              {
                                id: 1,
                                name: _t("strBalanced"),
                                filter1: _t("strBalanced"),
                              },
                              {
                                id: 2,
                                name: _t("strNonBalanced"),
                                filter1: _t("strNonBalanced"),
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="currId" label={_t("strCurrency")}>
                          <SelectSingle
                          allowClear={false}
                            fieldNames={{
                              label: "currName",
                              value: "currId",
                            }}
                            opt={currencyOpt}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{
                    width: "100%",
                    height: "95%",
                    margin: "5px 0px 5px 0px",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Form.Item
                    name="opEntIsPrevBalance"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>
                      {_t("strConvertOpeningEntryToPrevBalance")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="showMoveCurr"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>{_t("strShowCurrencyMovement")}</Checkbox>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FilterLedger;
