import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

export function emptValue(realVal, emptVal) {
  if (
    parseFloat(realVal) === 0 ||
    realVal === "" ||
    realVal === null ||
    isNaN(realVal) ||
    realVal === undefined ||
    realVal === "_"
  ) {
    return emptVal;
  } else {
    return parseFloat(realVal);
  }
}

export function emptString(realVal, emptVal) {
  if (
    realVal === "" ||
    realVal === "_" ||
    realVal === "00000000-0000-0000-0000-000000000000" ||
    realVal === null ||
    realVal === undefined
  ) {
    return emptVal;
  } else {
    return realVal;
  }
}

export function emptArr(realVal, emptVal) {
  if (
    realVal === null ||
    realVal === "" ||
    realVal === undefined ||
    realVal?.length === 0
  ) {
    return emptVal;
  } else {
    return realVal;
  }
}

export function columnsToObject(name) {
  return JSON.parse(localStorage.getItem(name))?.filter(
    (e) => e.key !== "rowNum"
  );
}

export function convertFloat(val) {
  if (val.startsWith(".")) {
    let result = "0" + val;
    return parseFloat(result);
  } else {
    return parseFloat(val);
  }
}

const getForLikeSearch = (searchName) => {
  // "\\",
  //   ".",
  //   "+",
  //   "*",
  //   "?",
  //   "[",
  //   "^",
  //   "]",
  //   "$",
  //   "(",
  //   ")",
  //   "{",
  //   "}",
  //   "=",
  //   "!",
  //   "<",
  //   ">",
  //   "|",
  //   ":",
  //   "-";
  searchName = searchName.toLowerCase();
  searchName = searchName
    .replaceAll("[", "\\[")
    .replaceAll("$", "\\$")
    .replaceAll("?", "\\?")
    .replaceAll("+", "\\+")
    .replaceAll("|", "\\|")
    .replaceAll("*", "\\*")
    .replaceAll("(", "\\(")
    .replaceAll(")", "\\)");

  searchName = ".*" + searchName.replaceAll(" ", ".* .*") + ".*";
  return searchName;
};

export const validateTreeSelectFilter = (search, item) => {
  return (
    (item?.filter1 ?? "") +
    " " +
    (item?.filter2 ?? "") +
    " " +
    (item?.filter3 ?? "") +
    " " +
    (item?.filter4 ?? "") +
    " " +
    (item?.filter5 ?? "")
  )
    .toLowerCase()
    .match(getForLikeSearch(search));
};

export const validateSelectFilter = (search, item) => {
  return (
    (item?.filter1 ?? "") +
    " " +
    (item?.filter2 ?? "") +
    " " +
    (item?.filter3 ?? "") +
    " " +
    (item?.filter4 ?? "") +
    " " +
    (item?.filter5 ?? "")
  )
    .toLowerCase()
    .match(getForLikeSearch(search));
};

export function checkDate(realVal) {
  return realVal !== undefined && realVal !== null && realVal !== ""
    ? true
    : false;
}


export function emptDate(realVal, emptVal) {
  return realVal !== undefined && realVal !== null && realVal !== ""
    ? dayjs(realVal)
    : emptVal;
}
export function subtractDate(realVal) {
  return dayjs(realVal).subtract(10, "days").format("DD/MM/YYYY - h:mm a");
}

export function dateFormating(date, format) {
  return dayjs(date).format(format);
}

export function dateRange(range) {
  return dayjs().startOf(range);
}

export const convertISOString = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return value?.toISOString().slice(0, 16) + "Z";
  } else return undefined;
};

export const dateTimeNow = () => {
  return dayjs();
};


export const convertDate =(nowDate)=>{

let nowDate1=new Date(nowDate);
let timeOffcet=nowDate1.getTimezoneOffset()*60000;
let finalDate=new Date(nowDate1.getTime()-timeOffcet);

// console.log(timeOffcet);
// console.log(nowDate1)
// console.log(finalDate.toISOString())
}



export const handleRange = (key, fromName, toName, form) => {
  // const daysOfWeek = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };
  dayjs.extend(quarterOfYear);
  dayjs.extend(isoWeek);
  const currentQuarter = dayjs().quarter();

  switch (key) {
    case 0:
      form.setFieldsValue({
        [fromName]: dayjs().startOf("day"),
        [toName]: dayjs().endOf("day"),
      });
      break;
    case 1:
      form.setFieldsValue({
        [fromName]: dayjs().subtract(1, "days").startOf("day"),
        [toName]: dayjs().subtract(1, "days").endOf("day"),
      });
      break;
    case 2:
      form.setFieldsValue({
        [fromName]: dayjs().subtract(10, "days").startOf("day"),
        [toName]: dayjs().endOf("day"),
      });
      break;
    case 3:
      form.setFieldsValue({
        [fromName]: dayjs().day(6).startOf("d"),
        [toName]: dayjs().day(11).endOf("d"),
      });
      break;
    case 4:
      form.setFieldsValue({
        [fromName]: dayjs().startOf("month"),
        [toName]: dayjs().endOf("month"),
      });
      break;
    case 5:
      form.setFieldsValue({
        [fromName]: dayjs().subtract(1, "month").startOf("month"),
        [toName]: dayjs().subtract(1, "month").endOf("month"),
      });
      break;
    case 6:
      form.setFieldsValue({
        [fromName]: dayjs().subtract(30, "days").startOf("day"),
        [toName]: dayjs().endOf("day"),
      });
      break;
    case 7:
      form.setFieldsValue({
        [fromName]: dayjs().quarter(currentQuarter).startOf("quarter"),
        [toName]: dayjs().quarter(currentQuarter).endOf("quarter"),
      });
      break;
    case 8:
      if (currentQuarter === 1) {
        form.setFieldsValue({
          [fromName]: dayjs()
            .subtract(1, "year")
            .startOf("year")
            .quarter(4)
            .startOf("quarter"),
          [toName]: dayjs()
            .subtract(1, "year")
            .startOf("year")
            .quarter(4)
            .endOf("quarter"),
        });
      } else {
        form.setFieldsValue({
          [fromName]: dayjs()
            .quarter(currentQuarter - 1)
            .startOf("quarter"),
          [toName]: dayjs()
            .quarter(currentQuarter - 1)
            .endOf("quarter"),
        });
      }
      break;
    case 9:
      form.setFieldsValue({
        [fromName]: dayjs().startOf("year"),
        [toName]: dayjs().endOf("day"),
      });
      break;
    case 10:
      form.setFieldsValue({
        [fromName]: dayjs().startOf("year"),
        [toName]: dayjs().endOf("year"),
      });
      break;
    case 11:
      form.setFieldsValue({
        [fromName]: dayjs().subtract(1, "year").startOf("year"),
        [toName]: dayjs().subtract(1, "year").endOf("year"),
      });
      break;
    default:
      form.setFieldsValue({ [fromName]: undefined, [toName]: undefined });
      break;
  }
};

export const convertDateRange = (range) => {
  let start = new Date(range[0]);
  let end = new Date(range[1]);

  return { start: start, end: end };
};
