
import { Space } from "antd";
import { stringify } from "rc-field-form/es/useWatch";
import { CellExpanderFormatter } from "./cellExpanderFormatter";
import styled from "styled-components";
const TreeFormatter = ({ row, obj, isCellSelected, dispatch }) => {
  const hasChildren = row.row?.children?.length !== 0;
  const tmpMargin = 20 * row.row?.level;
  const style = { marginInlineStart: tmpMargin};
 
  const line = () => {
    let arr =[]
    for (let i = 0; i < tmpMargin / 20; i++) {
      const Line = styled.div`
      {
        &::before,
        &::after {
          content: '';
          position: absolute;
          background: rgb(197, 197, 197);
        }
        &::before {
          inset-inline-start: ${(i * 20)+16}px;
          inline-size: 1px;
          block-size: 100%;
        }
    
      }
    `;
    arr.push(<Line/>) 
    }
    return arr
  }
 
  return (
    <div >
      {hasChildren ?
        <div style={style}>   
         {line()}
          <Space align="center">
            <CellExpanderFormatter
              isCellSelected={isCellSelected}
              expanded={row.row.isExpanded === true}
              onCellExpand={() => { return dispatch({ id: row.row.id, type: "toggleSubRow" }) }} />
            {row.row[obj.key.toString()]}
          </Space>     
        </div>
      :line()}
    </div>
  );
};

export default TreeFormatter;





