import { useFocusRef } from "./hooks";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";

export const CellExpanderFormatter = ({
  isCellSelected,
  expanded,
  onCellExpand
}) => {
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  const handleKeyDown = (e) => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      onCellExpand();
    }
  };
// set les for key expand
  return (
    <div className='cellExpandClassname' >
      <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
        <span ref={ref} tabIndex={tabIndex}>
          {expanded ? (
            <MinusSquareOutlined
              style={{
                fontSize: "18px",
                color: "#a8a8a8",
                // marginRight: 5,
                // marginLeft: 5
              }}
            />
          ) : (
            <PlusSquareOutlined
              style={{
                fontSize: "18px",
                color: "#a8a8a8",
                // marginRight: 5,
                // marginLeft: 5
              }}
            />
          )}
        </span>
      </span>
    </div>
  );
};
