import React, { useState, createContext, useContext } from "react";
import { languageOptions, dictionaryList } from "./langOptions";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import arEG from "antd/es/locale/ar_EG";

export const LanguageContext = createContext({
  userLanguage: "en",
  dictionary: dictionaryList.en,
  direction: "ltr",
});

export function LanguageProvider({ children, userDirection }) {
  const [userLanguage, setUserLanguage] = useState("en");
  const [userLocalization, setUserLocalization] = useState(enUS);

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    placement: userLanguage === "ar" ? "right" : "left",
    direction: userLanguage === "ar" ? "rtl" : "ltr",

    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : "en";
      setUserLanguage(newLanguage);
      window.localStorage.setItem("lang", newLanguage);
      if (userLanguage === "en") {
        setUserLocalization(enUS);
        userDirection("left");
      } else {
        setUserLocalization(arEG);
        userDirection("right");
      }
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#33cbcc",
            colorLink: "#33cbcc",
            colorLinkHover:"#2ac986",
            colorLinkActive:"#2ac986",
          },
        }}
        direction={userLanguage === "ar" ? "rtl" : "ltr"}
        locale={userLocalization}
      >
        <div style={{ direction: userLanguage === "ar" ? "rtl" : "ltr" }}>
          {children}
        </div>
      </ConfigProvider>
    </LanguageContext.Provider>
  );
}

export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[tid] || tid;
}
