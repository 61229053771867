import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, Divider, Space } from "antd";
import _t from "../../../../languages/translate";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
export const searchKey = "search";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// (( hiding )) → → → To show and hide search box .
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



const SearchT = ({ hiding = false }) => {
    let history = useHistory();
    let searchVal = new URLSearchParams(history.location.search).toString().split("search=").pop().split('&')[0];
    if (searchVal.includes("pageNumber")) { searchVal = "" }
    const [searchValue, setSearchValue] = useState(searchVal);
    const handleSearch = async (value) => {
        const query = new URLSearchParams(history.location.search);
        if (!value) query.delete(searchKey);
        else query.set(searchKey, value);
        query.set("pageNumber", "1");
        history.push({
            pathname: history.location.pathname,
            search: query.toString(),
        });
    };

    const handleSearchClear = () => {
        setSearchValue("");

        const query = new URLSearchParams(history.location.search);
        query.delete(searchKey);
        history.push({
            pathname: history.location.pathname,
            search: query.toString(),
        });
    };
    return (
        !hiding && <Space>
            <Divider type="vertical" />
            <Input
                defaultValue={
                    new URLSearchParams(history.location.search).get("search") ||
                    undefined
                }
                onChange={(e) => setSearchValue(e.target?.value)}
                placeholder={_t("strSearch")}
                onPressEnter={(e) => handleSearch(e.currentTarget.value)}
                bordered={false}
                size="small"
                value={searchValue}
                prefix={
                    searchValue === "" || searchValue === null ? (
                        <SearchOutlined style={{ color: "#a5a5a5" }} />
                    ) : (
                        <CloseCircleOutlined
                            onClick={() => handleSearchClear()}
                            style={{ color: "#a5a5a5" }}
                        />
                    )
                }
            />
            <Divider type="vertical" />
        </Space>
    )

}

export default SearchT;