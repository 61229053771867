import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/ReqApp";

export function getReqAppById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getPagerReqApp(query, obj) {
  return http.post(`${apiEndpoint}/GetPager?${query}`, obj);
}

export function getDetailsById(id) {
  return http.post(`${apiEndpoint}/GetDetailsById/?id=${id}`);
}

export function editReqApp(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteReqApp(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function bulkCreate(obj) {
  const data = {
    empIds: obj.empIds,
    date: obj.date,
    data: { ...obj },
  };
  return http.post(`${apiEndpoint}/BulkCreate`, data);
}
