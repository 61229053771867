import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/AttLogResMgr";

export function getAttLogResMgrById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getPagerAttLogResMgr(query, obj) {
  return http.post(`${apiEndpoint}/GetPager?${query}`, obj);
}

export function editAttLogResMgr(obj) {
  // if (obj.id !== "new") {
  //   return http.put(apiEndpoint, obj);
  // } else {
    
    return http.post(`${apiEndpoint}/Save`, obj);
  //}
}
