
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/InvPatt";

export function getInvPatt(query) {
  return http.post(`${apiEndpoint}/GetTreeRep?search=${query}`);
}

export function initiale(id) {
  return http.post(`${apiEndpoint}/Initiale?invType=${id}`);
}

export function getNewCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function editPattInv(obj) {
  
  if (obj.id !== "new" && obj.id !== undefined ) {
    return http.put(apiEndpoint, obj);
  } else {
   return http.post(apiEndpoint, obj);
 }
}

export function getInvPattById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function deleteInvPatt(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}





