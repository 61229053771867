
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/item";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getItemById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editItem(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}



export function getInStockInfo(itemUnitMapId,date) {
  return http.post(`${apiEndpoint}/GetInStockInfo/?itemUnitMapId=${itemUnitMapId}&date=${date}`);
}

export function getPriceInfo(itemUnitMapId,date,rate) {
  return http.post(`${apiEndpoint}/GetPriceInfo/?itemUnitMapId=${itemUnitMapId}&date=${date}&rate=${rate}`);
}


export function deleteItem(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getItem(value) {
  return http.get(`${apiEndpoint}/?search=${value}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllItem() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewItemCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerItem(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getActiveTreeRepItems(obj) {
  return http.post(`${apiEndpoint}/GetActiveTreeRep`,obj);
}

export function searchItem(code, name, isMatching) {
  return http.post(
    `${apiEndpoint}/GetChildrenActiveSearch?Code=${code}&Name=${name}&IsMatching=${isMatching}`
  );
}

export function getActiveTreeItem(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveTree/?search=${search}&addId=${addId}`
  );
}

export function getSearchPopup(search) {
  return http.post(
    `${apiEndpoint}/GetSearchPopup/?q=${search}`
  );
}


export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}


export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}