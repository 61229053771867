import _t from "../../../languages/translate";
import { emptString } from "../../helper/helperMethods";
import { Divider, Space, Tag, Typography } from "antd";

const { Text } = Typography;

export const _title = (e) => (
  <Space wrap size="small" className="spaceRes">
  <Text className="title" strong>{e?.empName}</Text> 
    {e?.status !== undefined && _tag(e)}
  </Space>
);

// export const _extra = (e) => <Text strong>{e?.reqDate}</Text>;

export const _titleTag = (e) => <Tag
      color={
        e?.status === 1
          ? "red"
          : e?.status === 2
          ? "green"
          : e?.status === 3
          ? "purple"
          : "blue"
      }
    >
      {e?.status === 0
        ? _t("strWaiting")
        : e?.status === 1
        ? `${_t("strRejected")}`
        : e?.status === 2
        ? `${_t("strAccepted")}`
        : e?.status === 3
        ? `${_t("strApplied")}`
        : ""}
    </Tag>
  ;


export const _tag = (e) => 
<Tag
    color="blue"
  >
    {_t("strRequest")} : {e?.reqDate}
      
  </Tag>;


export const _content = (e) => (
  <Space direction="vertical">
    <Space size="small" wrap split={<Divider type="vertical" />}>
      {emptString(e?.timeTypeName, false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.timeTypeName}</Text>
        </Text>
      )}
      {emptString(e?.salaryAdvTypeName, false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.salaryAdvTypeName}</Text>
        </Text>
      )}
      {emptString(e?.errandVacationName, false) && (
        <Text type="secondary">
          {_t("strType")} : <Text mark>{e?.errandVacationName}</Text>
        </Text>
      )}
      {emptString(e?.value, false) && (
        <Text type="secondary">
          {_t("strAmount")} : <Text mark>{e?.value}</Text>
        </Text>
      )}

      {emptString(e?.applyDate, false) && (
        <Text type="secondary">
          {_t("strDate")} : <Text>{e?.applyDate}</Text>
        </Text>
      )}

      {emptString(e?.applyFromDate, false) && (
        <Text type="secondary">
          {_t("strFrom")} : <Text>{e?.applyFromDate}</Text>
        </Text>
      )}

      {emptString(e?.applyToDate, false) && (
        <Text type="secondary">
          {_t("strTo")} : <Text>{e?.applyToDate}</Text>
        </Text>
      )}
    </Space>
    {emptString(e?.note, false) && (
      <Text type="secondary">
        {_t("strNote")} : <Text>{e?.note}</Text>
      </Text>
    )}
  </Space>
);
