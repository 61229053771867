
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/PriceMethod";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getPricingMethodsById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editPricingMethods(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}
    
export function getAllCurrencyRate() {
  return http.get(`${apiEndpoint}`);
}
export function deletePricingMethods(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerCurrencyRate(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getActivePriceMethodSearch(search) {
  return http.post(
    `${apiEndpoint}/GetActiveSearch/?search=${search}`
  );
}

export function getActiveCalcSearch(search) {
  return http.post(
    `${apiEndpoint}/GetActiveCalcSearch/?search=${search}`
  );
}

export function getNewPricingMethodsCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
