import React, { useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Card, Space, Typography } from "antd";
import SendOrderModal from "../forms/sendOrder/fr_SendOrderForm";

import absence from "../../images/jpeg/absence.jpg";
import appointment from "../../images/jpeg/appointment.jpg";
import cash from "../../images/jpeg/cash.jpg";
import suitcase from "../../images/jpeg/suitcase.jpg";
import upcoming from "../../images/jpeg/upcoming.jpg";
import vacation from "../../images/jpeg/vacation.jpg";
import wait from "../../images/jpeg/wait.jpg";

const CardsScreen = ({ list, showEmpList }) => {
  const [showModal, setShowModal] = useState(false);
  const [orderType, setOrderType] = useState(1);

  const jpegType = (key) => {
    return key === "key_absence"
      ? absence
      : key === "key_appointment"
      ? appointment
      : key === "key_cash"
      ? cash
      : key === "key_suitcase"
      ? suitcase
      : key === "key_upcoming"
      ? upcoming
      : key === "key_vacation"
      ? vacation
      : key === "key_wait"
      ? wait
      : "";
  };

  const fillCards = (list) => {
    if (list?.length > 0) {
      return list.map((e) => {
        return (
          <Card
            bordered={false}
            className="cardHome"
            onClick={async () => {
              await setOrderType(e.type);
              await setShowModal(true);
            }}
            title={
              <label
                style={{
                  fontWeight: 800,
                  fontSize: 12,
                  wordWrap: "break-word",
                }}
              >
                {e?.title}
              </label>
            }
          >
            <a>
              <img
                class="active"
                style={{ width: 75 }}
                alt={e?.key}
                src={jpegType(e?.key)}
              />
            </a>
          </Card>
        );
      });
    }
  };

  return (
    <>
      <SendOrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        orderType={orderType}
        showEmpList={showEmpList}
      />
      <Space wrap size="small">
        {fillCards(list)}
      </Space>
    </>
  );
};
export default CardsScreen;
