
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Report";



// Ledger
export function getLedgerReport(query, obj) {
  return http.post(`${apiEndpoint}/Ledger?${query}`, obj);
}

export function getLedgerHtml(obj, lang) {
  return http.post(`${apiEndpoint}/LedgerHtml?Lang=${lang}`, obj);
}

export function getLedgerXls(query, obj, lang) {
  return http.post(apiEndpoint + `/LedgerXls`, obj);
}

export function getLedgerPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/LedgerPdf?Lang=${lang}`, obj);
}

// .Item Movement
export function getItemMovmentReport(query, obj) {
  return http.post(`${apiEndpoint}/ItemMove?${query}`, obj);
}

export function getItemMovmentHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/ItemMoveHtml?Lang=${lang}`, obj);
}

export function getItemMovmentXls(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemMoveXls`, obj);
}

export function getItemMovmentPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemMovePdf?Lang=${lang}`, obj);
}


// .ItemInStock
export function getItemStockReport(query, obj) {
  return http.post(`${apiEndpoint}/ItemInStock?${query}`, obj);
}

export function getItemStockHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/ItemInStockHtml?Lang=${lang}`, obj);
}

export function getItemStockXls(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemInStockXls`, obj);
}

export function getItemStockPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemInStockPdf?Lang=${lang}`, obj);
}


// .Invoice
export function getInvoicesReport(query, obj) {
  return http.post(`${apiEndpoint}/Inv?${query}`, obj);
}

export function getInvoiceHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/InvHtml?Lang=${lang}`, obj);
}

export function getInvoiceXls(query, obj, lang) {
  return http.post(apiEndpoint + `/InvXls`, obj);
}

export function getInvoicePdf(query, obj, lang) {
  return http.post(apiEndpoint + `/InvPdf?Lang=${lang}`, obj);
}

// .TrialBalance
export function getTrialBalanceReport(query, obj) {
  return http.post(`${apiEndpoint}/TrialBalance?${query}`, obj);
}

export function getTrialBalanceHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/TrialBalanceHtml?Lang=${lang}`, obj);
}

export function getTrialBalanceXls(query, obj, lang) {
  return http.post(apiEndpoint + `/TrialBalanceXls`, obj);
}

export function getTrialBalancePdf(query, obj, lang) {
  return http.post(apiEndpoint + `/TrialBalancePdf?Lang=${lang}`, obj);
}



// .Journal
export function getJournalReport(query, obj) {
  return http.post(`${apiEndpoint}/Journal?${query}`, obj);
}

export function getJournalHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/JournalHtml?Lang=${lang}`, obj);
}

export function getJournalXls(query, obj, lang) {
  return http.post(apiEndpoint + `/JournalXls`, obj)
}

export function getJournalPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/JournalPdf?Lang=${lang}`, obj);
}

// .Munf
export function getMunfReport(query, obj) {
  return http.post(`${apiEndpoint}/Munf?${query}`, obj);
}

export function getMunfHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/MunfHtml?Lang=${lang}`, obj);
}

export function getMunfXls(query, obj, lang) {
  return http.post(apiEndpoint + `/MunfXls`, obj);
}

export function getMunfPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/MunfPdf?Lang=${lang}`, obj);
}
