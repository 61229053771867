import React, { useRef, useState } from "react";
import { DatePicker, Col, Row, Card, message } from "antd";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import SelectSingle from "../selectSingle";
import { emptDate, handleRange } from "../helperMethods";
import _t from "../../../languages/translate";

const TimeRange = ({ mainForm,
    form,
    lable,
    fromName = "fromDate",
    toName = "toDate",
    defaultRange = null,
    hideRange = false,
    hideFrom = false,
    hideTo = false,
    spanRange = 8,
    spanFrom = 8,
    spanTo = 8,
    noStyleFrom = false,
    noStyleTo = false,
}) => {
    const [statusTo, setStatusTo] = useState("");
    const [statusFrom, setStatusFrom] = useState("");
    const refFrom = useRef(null);
    const refTo = useRef(null);

    const dateList = [
        { id: 0, name: _t("strToday"), filter1: _t("strToday") },
        { id: 1, name: _t("strYesterday"), filter1: _t("strYesterday") },
        { id: 2, name: _t("strLast10day"), filter1: _t("strLast10day") },
        { id: 3, name: _t("strThisWeek"), filter1: _t("strThisWeek") },
        { id: 4, name: _t("strThisMonth"), filter1: _t("strThisMonth") },
        { id: 5, name: _t("strPrevMonth"), filter1: _t("strPrevMonth") },
        { id: 6, name: _t("strLast30Day"), filter1: _t("strLast30Day") },
        { id: 7, name: _t("strCurrentQuarter"), filter1: _t("strCurrentQuarter") },
        { id: 8, name: _t("strPrevQuarter"), filter1: _t("strPrevQuarter") },
        { id: 9, name: _t("strStartYear"), filter1: _t("strStartYear") },
        { id: 10, name: _t("strThisYear"), filter1: _t("strThisYear") },
        { id: 11, name: _t("strPrevYear"), filter1: _t("strPrevYear") },
    ]

    const handleDate = (field) => {   
        const from = form.getFieldValue(fromName);
        const to = form.getFieldValue(toName);
        
        if (emptDate(to,null) !== null && emptDate(from,null) !== null) {
            if (to < from) {
                message.error(_t("strErrorDateSelected"));
                if (field === "from") {
                    refFrom.current.focus();
                    setStatusFrom("error");
                }
                else {
                    refTo.current.focus();
                    setStatusTo("error");
                }
            } else {
                setStatusFrom("");
                setStatusTo("");
            }
        } else {
            setStatusFrom("");
            setStatusTo("");
        }
    }

    return (

        <mainForm.Item label={lable} style={{ width: "100%" }} >

            <Card bodyStyle={{ padding: "0 5px", background: "#f9f9f9" }}>

                <Col xs={24} >
                    <Row gutter={3}>
                        {!hideRange &&
                            <Col span={spanRange}>
                                <mainForm.Item label={_t("strRange")} >
                                    <SelectSingle
                                        defaultValue={defaultRange}
                                        onChange={(key) => { handleRange(key, fromName, toName, form) }}
                                        opt={dateList}
                                    />
                                </mainForm.Item>
                            </Col>
                        }

                        {!hideFrom &&
                            <Col span={spanFrom}>
                                <mainForm.Item name={fromName} label={_t("strFrom")} noStyle={noStyleFrom}>
                                    <DatePicker
                                        showTime={{ format: 'HH:mm:ss' }}
                                        format={dateFormatList}
                                        className="dateTimeStyle"
                                        onChange={() => handleDate("from")}
                                        size="small"
                                        ref={refFrom}
                                        status={statusFrom}
                                    />
                                </mainForm.Item>
                            </Col>
                        }
                        {!hideTo &&
                            <Col span={spanTo}>
                                <mainForm.Item name={toName} label={_t("strTo")} noStyle={noStyleTo}>
                                    <DatePicker
                                        showTime={{ format: 'HH:mm:ss' }}
                                        format={dateFormatList}
                                        className="dateTimeStyle"
                                        onChange={() => handleDate("to")}
                                        size="small"
                                        ref={refTo}
                                        status={statusTo}
                                    />
                                </mainForm.Item>
                            </Col>
                        }
                    </Row>
                </Col>

            </Card>
        </mainForm.Item>

    );
};

export default TimeRange;
